import { Component } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";

class JwtService extends Component {
  constructor(props) {
    super(props);
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  }

  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          toast.error(err.response.data.message);
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const accessToken = this.getAccessToken();
    const refreshToken = this.getRefreshToken();

    if (!accessToken) {
      return;
    }

    if (this.isAuthTokenValid(accessToken)) {
      this.setSession(accessToken, refreshToken);
    } else {
      this.setSession(null);
    }
  };

  checkToken = () => {
    return new Promise((resolve, reject) => {
      // If token null, skip refresh token.
      let accessToken = this.getAccessToken();
      if (!accessToken) {
        reject();
        return;
      } else {
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      }

      axios
        .post("v1/auth/info")
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          this.logout();
          reject(new Error("Failed to login with token."));
        });
    });
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/auth/register", data)
        .then((response) => {
          if (response.data.user) {
            this.setSession(
              response.data.tokens.access.token,
              response.data.tokens.refresh.token
            );
            resolve(response.data.user);
          } else {
            reject(response.data.error);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  forgotPasswordRequest = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/auth/forgot-password", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  resetPasswordRequest = (data) => {
    return new Promise((resolve, reject) => {
      console.log({ data });
      axios
        .post("/v1/auth/reset-password", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  googleProviderSign = (googleResponse) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/auth/signUpWithGoogle", googleResponse)
        .then((response) => {
          if (response.data.user) {
            this.setSession(
              response.data.tokens.access.token,
              response.data.tokens.refresh.token
            );
            resolve(response.data.user);
          } else {
            reject(response.data.error);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  facebookProviderSign = (facebookResponse) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/auth/signUpWithFacebook", facebookResponse)
        .then((response) => {
          if (response.data.user) {
            this.setSession(
              response.data.tokens.access.token,
              response.data.tokens.refresh.token
            );
            resolve(response.data.user);
          } else {
            reject(response.data.error);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  appleProviderSign = (facebookResponse) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/auth/signUpWithApple", facebookResponse)
        .then((response) => {
          if (response.data.user) {
            this.setSession(
              response.data.tokens.access.token,
              response.data.tokens.refresh.token
            );
            resolve(response.data.user);
          } else {
            reject(response.data.error);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  verifyGsmNo = (code) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/auth/verify-gsm-no", { code })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  saveGsmNo = (phone) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/auth/save-gsm-no", { phone })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  signInWithCredential = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/v1/auth/login", {
          email,
          password,
        })
        .then((response) => {
          if (response.data.user) {
            this.setSession(
              response.data.tokens.access.token,
              response.data.tokens.refresh.token
            );
            resolve(response.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  setSession = (accessToken, refreshToken) => {
    if (accessToken) {
      localStorage.setItem("jwt_accessToken", accessToken);
      localStorage.setItem("jwt_refreshToken", refreshToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.clear();
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_accessToken");
  };

  getRefreshToken = () => {
    return window.localStorage.getItem("jwt_refreshToken");
  };

  isAuthTokenValid = (accessToken) => {
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    }

    return true;
  };
}

const instance = new JwtService();

export default instance;
