import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthRoute from 'modules/Auth/routes/AuthRoute';
import { generateRoutesFromConfigs } from 'helpers';
import ProfileRoute from 'modules/Auth/routes/ProfileRoute';
import CheckoutPrivateRoute from 'modules/Checkout/routes/CheckoutPrivateRoute';
import CheckoutPublicRoute from 'modules/Checkout/routes/CheckoutPublicRoute';
import CategoryPublicRoute from 'modules/Category/routes/CategoryPublicRoute';
import MainPublicRoute from 'modules/Main/routes/MainPublicRoute';
import AdminPrivateRoute from 'modules/Admin/routes/AdminPrivateRoute';
//import SmsRoute from "modules/Auth/routes/SmsRoute";
import PagePublicRoute from 'modules/Page/routes/PagePublicRoute';
import ContactRoute from '../modules/Contact/routes/ContactRoute';
import BarcodeRoute from '../modules/Barcode/routes/BarcodePublicRoute';
import BarcodePublicRoute from '../modules/Barcode/routes/BarcodePublicRoute';
import BasketPublicRoute from '../modules/Basket/routes/BasketPublicRoute';
import ProductPublicRoute from '../modules/Product/routes/ProductPublicRoute';
import OrderPublicRoute from '../modules/Order/routes/OrderPublicRoute';
import StaffPrivateRoute from '../modules/Staff/routes/StaffPrivateRoute';

const routeConfigs = [
  // Page
  PagePublicRoute,

  // Category
  CategoryPublicRoute,

  // Barcode
  ProductPublicRoute,
  BarcodePublicRoute,
  BasketPublicRoute,

  // Sms Otp
  //SmsRoute,

  //Contact

  ContactRoute,

  // Buy acoin
  /*
  CheckoutPrivateRoute,
*/
  CheckoutPublicRoute,

  // Orders
  OrderPublicRoute,

  // Auth and custom
  AuthRoute,
  ProfileRoute,

  // Staff (Monitors, Kiosks)
  StaffPrivateRoute,

  // Admin private
  AdminPrivateRoute,

  // Main Homepage - it should be at the end
  MainPublicRoute,
];

const routes = [
  ...generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    component: () => <Redirect to="/example" />,
  },
];

export default routes;
