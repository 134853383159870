import React from 'react';
import { Link } from 'react-router-dom';
import { generateAdvertUrl } from '../../../../helpers';

const AdminUserBoughts = (props) => {
  const { boughts } = props;
  return (
    <div className="row">
      <div style={{ width: '100%', overflow: 'auto' }}>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">İlan</th>
              <th scope="col">Ödenen ALCoin</th>
              <th scope="col">Açma Tarihi</th>
            </tr>
          </thead>
          <tbody>
            {boughts.map((item, index) => (
              <tr>
                <th scope="row">{index + 1}</th>
                <th scope="row">
                  <Link to={generateAdvertUrl(item.advert.slug)} target="_blank">
                    {item.advert.title}
                  </Link>
                </th>
                <th>{item.advert.acoin}</th>
                <th scope="row">{item.createdAt}</th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminUserBoughts;
