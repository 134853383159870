import Homepage from '../Homepage';
import Dashboard from '../Dashboard';
import CustomerSessions from '../CustomerSessions';

const MainPublicRoute = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/sessions',
      component: CustomerSessions,
    },
    {
      path: '/dashboard',
      component: Dashboard,
    },
    {
      path: '/',
      component: Homepage,
    },
  ],
};

export default MainPublicRoute;
