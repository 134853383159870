import React from 'react';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import * as adminEndpoint from 'modules/Admin/_redux/adminEndpoint';
import AdminLayout from 'markup/Layout/AdminLayout';

const AdminPages = (props) => {
  const history = useHistory();
  console.log(history);
  return (
    <AdminLayout className="col-md-12">
      <MaterialTable
        title="Sayfalar"
        options={{ search: false }}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} / {count}',
          },
          header: {
            actions: 'İşlem',
          },
          body: {
            emptyDataSourceMessage: '',
            filterRow: {
              filterTooltip: 'Filtre',
            },
          },
        }}
        actions={[
          {
            icon: 'add',
            tooltip: 'Yeni Ekle',
            isFreeAction: true,
            onClick: (event) => history.push('/admin/pages/create'),
          },
        ]}
        columns={[
          { title: 'id', field: 'ID', hidden: true },
          { title: 'Başlık', field: 'title' },
          { title: 'Slug', field: 'slug' },
          { title: 'Oluşturma', field: 'createdAt' },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            adminEndpoint
              .getPages(query.page, query.pageSize, 'desc')
              .then((res) => res.data)
              .then((result) => {
                resolve({
                  data: result.results,
                  page: result.page - 1,
                  totalCount: result.totalResults,
                });
              });
          })
        }
        editable={{
          isEditable: (rowData) => rowData.id,
          isDeletable: (rowData) => rowData.id,
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              adminEndpoint.deletePage(oldData.id).then((result) => {
                resolve();
              });
            }),
        }}
      />
    </AdminLayout>
  );
};

export default AdminPages;
