import React, { useEffect, useState } from 'react';
import LayoutV1 from 'markup/Layout/LayoutV1';
import { getBarcode, getRequestWaiterFromBarcode } from '../_redux/barcodeEndpoint';
import { connect } from 'react-redux';
import * as initialRedux from '../../Initial/_redux/initialRedux';
import { Backdrop, Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { generateOnlineMenuUrl } from 'helpers';
import { toast } from 'react-toastify';
import { makeImageUrl } from 'helpers/PathHelper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

function upper(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
function BarcodeDetail(props) {
  const classes = useStyles();
  const [barcode, setBarcode] = useState({ company: { name: '' } });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let deskCode = props.match.params.deskCode;
    window.scrollTo(0, 0);

    // props.createSession()
    getBarcode(deskCode).then((res) => {
      setLoading(false);
      setBarcode(res.data.barcode);
      // setSession(res.data);
      // props.createSessionFromDesk(res.data);
      props.createSessionFromDesk(res.data.createdSession);
    });
  }, []);

  function showMessage(message) {
    toast.success(message);
    // const playerId = await OneSignal.getPlayerId();
    // console.log(playerId);
  }

  function requestWaiter(message) {
    // toast.success(message);
    // const playerId = await OneSignal.getPlayerId();
    let deskCode = props.match.params.deskCode;

    getRequestWaiterFromBarcode(deskCode)
      .then((res) => res.data)
      .then((result) => {
        toast.success('Birazdan masanıza gelecektir..');
      });

    // console.log(playerId);
  }
 if (loading) {
    return (
      <LayoutV1>
        <Backdrop open={true}>Yükleniyor...</Backdrop>
      </LayoutV1>
    );
  }
  return (
    <LayoutV1>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="page-content">
        <div className="container">
          <div className="row mb-3">
            <div className="col-xs-12 px-2">
              <div className="company-information">
                {loading && <Skeleton variant="rect" width={315} height={236} />}
                <img src={makeImageUrl(barcode.company.image)} className="rounded-xl-r" alt="" />
                <br />
              </div>

              <div className="profile-header-area space-pt--30 ">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="profile-header">
                        <div className="profile-header__content space-mt--10">
                          <h3 className="name space-mb--15">{barcode.company.name}</h3>
                          <div className="profile-info space-mb--10">
                            <div className="profile-info-block">

                              <div className="profile-info-block__value">
                                {loading && <Skeleton variant="rect" width={50} height={15} />}
                                {barcode.name}
                              </div>
                              <div className="profile-info-block__title">Alan / Masa</div>

                            </div>
                            <div className="profile-info-block">
                              <div className="profile-info-block__value">Açık</div>
                              <div className="profile-info-block__title">Online Sipariş</div>
                            </div>
                            <div className="profile-info-block">
                              <div className="profile-info-block__value">
                                {props.lastReadedSettings.paymentType.map((item, index) => (
                                  <span key={index}> {upper(item)} </span>
                                ))}
                              </div>
                              <div className="profile-info-block__title">Ödeme Metodu</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <button
                        className="btn-block border shadow rounded bg-white p-2 my-2 d-flex justify-content-between align-items-center"
                        onClick={() => props.history.push(generateOnlineMenuUrl(props.match.params.deskCode))}
                      >
                        Menüye Git
                        <Icon>double_arrow</Icon>
                      </button>

                      <div
                        className="border shadow rounded bg-white p-2 my-2 d-flex justify-content-between align-items-center"
                        onClick={() => requestWaiter()}
                        style={{ cursor: 'pointer' }}
                      >
                        Garson Çağır
                        <Icon>add_alert</Icon>
                      </div>

                      {/* <div
                        className="border shadow rounded bg-white p-2 my-2 d-flex justify-content-between align-items-center"
                        onClick={() => showMessage('Requested for desk. ')}
                      >
                        Create Session for {barcode.name}
                        <Icon>double_arrow</Icon>
                      </div>*/}

                      <Link
                        className="border shadow rounded bg-white p-2 my-2 d-flex justify-content-between align-items-center"
                        to="/orders"
                      >
                        Siparişlerim
                        <Icon>double_arrow</Icon>
                      </Link>

                      <Link
                        className="border shadow rounded bg-white p-2 my-2 d-flex justify-content-between align-items-center"
                        to={`/sessions`}
                      >
                        Oturumlarım
                        <Icon>double_arrow</Icon>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutV1>
  );
}

// export default BarcodeDetail;
const mapStateToProps = (state) => ({
  initialRedux: state.initial.initialRedux,
  lastReadedSettings: state.initial.lastReadedSettings,
});

export default connect(mapStateToProps, { ...initialRedux.actions })(BarcodeDetail);
