import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function Footer(props) {
  const { pages } = useSelector(
    (state) => ({
      pages: state.initial.pages,
    }),
    shallowEqual
  );

  return (
    <footer className=" ">
      <div className=" bg-black-light color-white" style={{ height: 300 }}>
        <div className="row">
          <div className="col-12">
            <div className="container">
              <div className="mt-5 d-flex justify-content-between">
                <div>
                  <h3 className="mb-3">Menü</h3>
                  <ul>
                    <li>
                      <Link to="/">Ana Sayfa</Link>
                    </li>
                    <li>
                      <Link to="/login">Admin Girişi</Link>
                    </li>
                    <li>
                      <Link to="/page/log">Değişiklik Geçmişi</Link>
                    </li>
                  </ul>
                </div>

                <div>
                  <h5 className="mb-3">Link</h5>
                  <ul>
                    <li>
                      <a href="https://codenratech.co.uk" target="_blank">
                        Codenra Tech
                      </a>
                    </li>
                    <li>
                      <a href="https://codenra.co.uk" target="_blank">
                        Codenra
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*<div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-9">
              Codenra
            </div>

          </div>
        </div>
      </div>*/}
    </footer>
  );
}

export default Footer;
