import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as auth from 'modules/Auth/_redux/authRedux';

import JWTService from '@core/auth/JWTService';
import SocialBar from './parts/SocialBar';

function Register(props) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    password: '',
  });

  function registerLocal(e) {
    e.preventDefault();
    setLoading(true);
    JWTService.createUser(form)
      .then((userInformation) => {
        props.registerAction(userInformation);
      })
      .then((result) => {
        // info().then((res) => props.setBalance(res.data.balance));
        props.setUserData(result);
      })
      .then((result) => {
        props.history.push(`/otp`);
      })
      .catch((err) => {
        //toast.error(err.response.data.message);
        setLoading(false);
      });
  }

  function handleChange(event) {
    event.persist();
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div className="bg-img-fix" style={{ height: '100vh' }}>
          <div className="row d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="col-3 bg-white z-index2 relative p-a0 content-scroll skew-section left-bottom">
              <div className="login-form style-2 col-md-12 col-xs-3">
                <div className="tab-content nav p-b30 tab">
                  <div className="p-3">
                    <div className="dez-separator-outer m-b5 mb-5">
                      <h5 className="form-title m-t0">Register disabled. Please call customer call center.</h5>
                    </div>
                    <form className=" dez-form p-b30">
                      {/*
                      <div className="dez-separator-outer m-b5 mb-5">
                        <h5 className="form-title m-t0">Demand </h5>
                      </div>

                      <div className="form-group">
                        <div className="form-group col-md-12">
                          <input
                            name="name"
                            autoFocus
                            required
                            className="form-control"
                            value={form.name}
                            onChange={handleChange}
                            placeholder="Name"
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <input
                          name="email"
                          required
                          className="form-control"
                          value={form.email}
                          onChange={handleChange}
                          placeholder="E-Mail"
                          type="email"
                        />
                      </div>

                      <div className="form-group">
                        <input
                          name="password"
                          required=""
                          className="form-control "
                          value={form.password}
                          onChange={handleChange}
                          placeholder="Password"
                          type="password"
                        />
                      </div>

                      <div className="form-group text-left">
                        <div className="d-flex justify-content-between align-items-center">
                          <button className="site-button dz-xs-flex m-r5" disabled={loading} onClick={registerLocal}>
                            {loading ? 'Loading..' : 'Register'}
                          </button>
                          <span className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="check1" name="example1" />
                            <label className="custom-control-label" htmlFor="check1">
                              Remember Me
                            </label>
                          </span>
                        </div>
                      </div>
                      <SocialBar />
                    */}
                    </form>
                  </div>
                </div>
                <div className="bottom-footer clearfix m-t10 m-b20 row text-center">
                  <div className="col-lg-12 text-center">
                    <span>
                      <a href="https://codenra.co.uk" target="_blank" style={{ fontSize: 12 }}>
                        Powered by Codenra
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, auth.actions)(Register);
