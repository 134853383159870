import React, { useEffect } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import { connect } from 'react-redux';
import * as auth from 'modules/Auth/_redux/authRedux';
import LayoutMargin from './LayoutMargin';
import 'css/tail.css';

const array = [
  { id: 1, title: 'Dashboard', link: 'dashboard', icon: 'home' },
  { id: 5, title: 'Zones', link: 'barcodes', icon: 'branding_watermark' },
];

function StaffLayout(props) {
  function exit() {
    props.logout();
  }
  useEffect(() => {}, []);

  return (
    <LayoutMargin>
      <div className="container">
        <div className="row">{props.children}</div>
      </div>
    </LayoutMargin>
  );
}

export default connect(auth.reducer, auth.actions)(StaffLayout);
