import axios from 'axios';

export const DASHBOARD = 'v1/admin/dashboard';
export const USERS = 'v1/admin/users';
export const PRODUCTS = 'v1/admin/products';
export const ORDERS = 'v1/admin/orders';
export const EMPLOYEES = 'v1/admin/employees';
export const CATEGORIES = 'v1/admin/categories';
export const PAGE = 'v1/admin/pages';
export const BARCODES = 'v1/admin/barcodes';
export const SETTINGS = 'v1/admin/settings';

// axios.defaults.headers.post['header1'] = 'value'; // for POST requests
axios.defaults.headers.post['header1'] = localStorage.getItem('mId'); // for POST requests
// axios.defaults.headers.get['header1'] = 'value'; // for POST requests

/**
 * DASHBOARD
 */
export function getDashboard() {
  return axios.get(`${DASHBOARD}`);
}

/**
 * USERS
 * @param {number} page
 * @param {number} pageSize
 * @param {string} order
 */
export function getUsers(page, pageSize, order) {
  return axios.get(`${USERS}?page=${page + 1}&limit=${pageSize}&sortBy=createdAt:${order}`);
}

export function getUser(userId) {
  return axios.get(`${USERS}/${userId}`);
}
/* PRODUCTS */

export function getProducts(page, pageSize, order) {
  return axios.get(`${PRODUCTS}?page=${page + 1}&limit=${pageSize}&sortBy=createdAt:${order}`);
}
export function getProductDetail(productId) {
  return axios.get(`${PRODUCTS}/${productId}`);
}

export function createProductRequest(productForm) {
  return axios.post(`${PRODUCTS}`, productForm);
}

export function updateProductRequest(productId, productForm) {
  return axios.put(`${PRODUCTS}/${productId}`, productForm);
}

export function deleteProduct(productId) {
  return axios.delete(`${PRODUCTS}/${productId}`);
}

/* ORDERS */
export function getOrders(page, pageSize, order) {
  return axios.get(`${ORDERS}?page=${page + 1}&limit=${pageSize}&sortBy=createdAt:${order}&relation=user`);
}

export function approveOrderEndpoint(orderId) {
  return axios.get(`${ORDERS}/${orderId}/approve`);
}

/* EMPLOYEES */
export function getEmployees(page, pageSize, order) {
  return axios.get(`${EMPLOYEES}?page=${page + 1}&limit=${pageSize}&sortBy=createdAt:${order}&relation=user`);
}

export function createEmployeeRequest(employeeForm) {
  return axios.post(`${EMPLOYEES}`, employeeForm);
}

export function deleteEmployeeRequest(employeeId) {
  return axios.delete(`${EMPLOYEES}/${employeeId}`);
}

/* BARCODES */
export function getBarcode(barcodeId) {
  return axios.get(`${BARCODES}/${barcodeId}`);
}

export function getBarcodes(page, pageSize, order) {
  return axios.get(`${BARCODES}?page=${page + 1}&limit=${pageSize}&sortBy=createdAt:${order}&relation=user`);
}

export function getBarcodeCategory() {
  return axios.get(`${BARCODES}/category`);
}

export function createBarcodeRequest(barcodeForm) {
  return axios.post(`${BARCODES}`, barcodeForm);
}

export function deleteBarcodeRequest(barcodeId) {
  return axios.delete(`${BARCODES}/${barcodeId}`);
}

// PAGES
export function getPages(page, pageSize, order) {
  return axios.get(`${PAGE}?page=${page + 1}&limit=${pageSize}&sortBy=createdAt:${order}`);
}

export function createPage(form) {
  return axios.post(`${PAGE}`, form);
}

export function deletePage(pageId) {
  return axios.delete(`${PAGE}/${pageId}`);
}

// CATEGORY
export function getCategoryDetail(categoryId) {
  return axios.get(`${CATEGORIES}/${categoryId}`);
}

export function postCategoryDetail(categoryId, editedCategory) {
  return axios.post(`${CATEGORIES}/${categoryId}`, { editedCategory });
}

export function getCategories() {
  return axios.get(`${CATEGORIES}`);
}

export function createCategory(form) {
  return axios.post(`${CATEGORIES}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function deleteCategory(categoryId) {
  return axios.delete(`${CATEGORIES}/${categoryId}`);
}

// SETTINGS
export function getSettings() {
  return axios.get(`${SETTINGS}`);
}

export function saveSettings(settingsBody) {
  return axios.post(`${SETTINGS}`, settingsBody);
}

export function saveMasterImage(imageBody) {
  return axios.post(`${SETTINGS}/saveMasterImage`, imageBody);
}
