import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from 'modules/Auth/_redux/authRedux';
import * as initial from 'modules/Initial/_redux/initialRedux';
import * as admin from 'modules/Admin/_redux/adminRedux';
import * as barcode from 'modules/Barcode/_redux/barcodeRedux';
import * as basket from 'modules/Basket/_redux/basketRedux';
import * as orders from 'modules/Order/_redux/orderRedux';
import * as category from 'modules/Category/_redux/categoryRedux';
// import * as page from 'modules/Page/_redux/pageRedux';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  admin: admin.reducer,
  initial: initial.reducer,
  barcode: barcode.reducer,
  basket: basket.reducer,
  orders: orders.reducer,
  category: category.reducer,
  //page: page.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
