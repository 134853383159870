import React, { useState } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import LayoutV1 from 'markup/Layout/LayoutV1';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as initialRedux from 'modules/Initial/_redux/initialRedux';
import BreadcrumbsCustom from '../../components/BreadcrumbsCustom';
import LayoutMargin from '../../markup/Layout/LayoutMargin';

function CustomerSessions(props) {
  return (
    <LayoutMargin>
      <BreadcrumbsCustom pageTitle="Son Oturumlar" prevUrl={'/home'} />
      <div className="page-content">
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-lg-12 search-box-container">
              <div>
                {props.lastSessions.map((item, index) => (
                  <div className="last-session-item">
                    <div>{item.id}</div>
                    <div>{item.name}</div>
                    <div>{item.company.name}</div>
                    <div>{item.company.address}</div>
                    <Link className="btn btn-primary" to={`/q/${item.id}`}>
                      Yeniden Kullan
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutMargin>
  );
}

const mapStateToProps = (state) => ({
  lastSessions: state.initial.deskSessions,
});

export default connect(mapStateToProps, { ...initialRedux.actions })(CustomerSessions);
