import React, { useEffect, useState } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import * as authRedux from 'modules/Auth/_redux/authRedux';
import * as initialRedux from 'modules/Initial/_redux/initialRedux';
import * as adminEndpoint from '../_redux/staffEndpoint';
import { toast } from 'react-toastify';
import moment from 'moment';
import pusher from 'services/PusherService';
import StaffLayout from 'markup/Layout/StaffLayout';

let alarm = require('images/alarm_tone.mp3');

function StaffDashboard(props) {
  const [dashboard, setDashboard] = useState({
    orders: [],
  });

  const [call, setCall] = useState([]);

  const [audio] = useState(new Audio(alarm));
  const [playing, setPlaying] = useState(true);

  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    loadOrders();
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  function loadOrders() {
    adminEndpoint
      .getStaffDashboard()
      .then((res) => res.data)
      .then((result) => {
        setDashboard({
          orders: result.orders,
        });
      });
  }

  useEffect(() => {
    const channel = pusher.subscribe(user.company);
    channel.bind('orderCompleted', orderCompleted);
    channel.bind('requestWaiterChannel', requestWaiterChannel);
    return () => {
      channel.unbind_all();
    };
  }, []);

  function orderCompleted(val) {
    setPlaying(true);
    loadOrders();
    toast.success('New order.');

    audio.play().then((res) => console.log('played', res));
  }

  function requestWaiterChannel(val) {
    toast.success(`${val.companyBarcode.name} need to service.`, { autoClose: 910000 });
    setCall((prevProps) => prevProps.concat({ ...val.companyBarcode, callDate: moment.now() }));
    audio.play().then((res) => console.log('played', res));
  }

  function approveOrder(orderId) {
    /*adminEndpoint.approveOrderEndpoint(orderId).then((res) => {
      loadOrders();
      toast.success('Order Approved.');
    });*/
  }

  return (
    <StaffLayout>
      <div className=" container ">
        <div className="flex flex-row">
          <div className="flex-1">
            <div className="flex justify-between border radius-md py-2 m-2">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam amet aut blanditiis iusto minus nemo neque
              saepe sapiente? Aliquid blanditiis eligendi est, fugiat itaque iure maxime nulla qui quos soluta!
            </div>
          </div>
          <div className="flex-1">
            {dashboard.orders.map((order, index) =>
              order.items.map((product, index2) => (
                <div className="flex flex-col border radius-md py-2 m-2 p-2 ">
                  <div className="flex justify-between leading-8 items-center">
                    <h4>{product.name}</h4>
                    <h5>{product.qty}X</h5>
                    <span>{moment(order.createdAt).fromNow()}</span>
                  </div>
                  <div></div>
                  <div className=" justify-between flex">
                    <button className="btn btn-primary">Start Job</button>
                    <button className="btn btn-warning">Pause Job</button>
                    <button className="btn btn-success">Finish Job</button>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </StaffLayout>
  );
}

export default connect(null, {
  ...authRedux.actions,
  ...initialRedux.actions,
})(StaffDashboard);
