import axios from 'axios';

export const GET_ORDERS = 'v1/orders';

export function getOrders(sessions) {
  return axios.post(`${GET_ORDERS}/getMyOrdersViaMySessions`, { sessions });
}
export function getOrder(orderId) {
  return axios.get(`${GET_ORDERS}/${orderId}`);
}
