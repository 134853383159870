import React from 'react';
import './adminUser.css';

const AdminUserGeneral = (props) => {
  const { user } = props;
  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-4 col-sm-2">
            <label className="p-1 text-black admin-user-label">Ad: </label>
          </div>
          <div className="col-8 col-sm-10">
            <label className="py-1 px-2 admin-user-label label-overflow">{user.name}</label>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-4 col-sm-2">
            <label className="p-1  text-black admin-user-label">Soyad: </label>
          </div>
          <div className="col-8 col-sm-10">
            <label className="py-1 px-2 admin-user-label label-overflow">{user.surname}</label>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-4 col-sm-2">
            <label className="p-1  text-black admin-user-label">Mail: </label>
          </div>
          <div className="col-8 col-sm-10">
            <label className="py-1 px-2 admin-user-label label-overflow">{user.email}</label>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-4 col-sm-2">
            <label className="p-1  text-black admin-user-label">Üyelik Tarihi: </label>
          </div>
          <div className="col-8 col-sm-10">
            <label className="py-1 px-2 admin-user-label label-overflow">{user.createdAt}</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUserGeneral;
