import React from 'react';
import { connect } from 'react-redux';
import * as authRedux from 'modules/Auth/_redux/authRedux';
import * as initialRedux from 'modules/Initial/_redux/initialRedux';
import AdminLayout from 'markup/Layout/AdminLayout';
import MaterialTable from 'material-table';
import * as adminEdnpoint from 'modules/Admin/_redux/adminEndpoint';
import { toast } from 'react-toastify';

function AdminBarcodes(props) {
  return (
    <AdminLayout>
      <div className="page-content ">
        <MaterialTable
          title="Odalar"
          options={{
            search: false,
            pageSize: 10,
            //toolbar: false,
          }}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} / {count}',
            },
            header: {
              actions: 'İşlemler',
            },
            body: {
              emptyDataSourceMessage: '',
              filterRow: {
                filterTooltip: 'Filter',
              },
            },
          }}
          actions={[
            {
              icon: 'contactless',
              tooltip: 'Detay',
              onClick: (event, rowData) => {
                props.history.push(`/admin/barcodes/${rowData.id}`);
              },
            },
            {
              icon: 'category',
              isFreeAction: true,
              tooltip: 'Kategori',
              onClick: (event, rowData) => {
                props.history.push(`/admin/barcodes/category`);
              },
            },
          ]}
          columns={[
            { title: 'ID', field: 'id', hidden: true },
            { title: 'İsim', field: 'name' },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              adminEdnpoint
                .getBarcodes(query.page, query.pageSize, 'desc')
                .then((res) => res.data)
                .then((result) => {
                  resolve({
                    data: result.results,
                    page: result.page - 1,
                    totalCount: result.totalResults,
                  });
                });
            })
          }
          editable={{
            isEditable: (rowData) => rowData.id,
            isDeletable: (rowData) => rowData.id,
            onRowDelete: (rowData) =>
              new Promise((resolve, reject) => {
                adminEdnpoint.deleteBarcodeRequest(rowData.id).then((res) => {
                  toast.success('Barcode deleted.');
                  resolve();
                });
              }),
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                adminEdnpoint.createBarcodeRequest(newData).then((res) => {
                  toast.success('Barcode created.');
                  resolve();
                });
              }),
          }}
        />
      </div>
    </AdminLayout>
  );
}

export default connect(null, {
  ...authRedux.actions,
  ...initialRedux.actions,
})(AdminBarcodes);
