import CategoryViewV1 from '../CategoryViewV1';

const CategoryPublicRoute = {
  routes: [
    {
      path: '/z/:deskCode/company/category/:categoryId',
      component: CategoryViewV1,
    },
  ],
};

export default CategoryPublicRoute;
