import axios from 'axios';

export const GET_CRUD = '';
export const START_3D = 'v1/orders/start3D';
export const CREATE_ORDER = 'v1/orders/create';

export function getCrud() {
  return axios.get(GET_CRUD);
}

export function start3D({ items, card }) {
  return axios.post(START_3D, { items, card });
}

export function createOrder(items, sessions, lastReadedBarcodeOfCompany) {
  return axios.post(CREATE_ORDER, { items, sessions, lastReadedBarcodeOfCompany });
}
