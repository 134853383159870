import React from 'react';

import 'react-pro-sidebar/dist/css/styles.css';
import LayoutV1 from './LayoutV1';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
let bnr = require('images/banner/bnr1.jpg');

function UserProfileLayout(props) {
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  return (
    <LayoutV1>
      <div className="overlay-black-dark profile-edit p-t50 p-b20" style={{ backgroundImage: 'url(' + bnr + ')' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-7 candidate-info mt-5">
              <div className="candidate-detail">
                <div className="text-white browse-job text-left">
                  <h4 className="m-b0">
                    {user.name} {user.surname}
                    <Link to={''} className="m-l15 font-16 text-white" data-toggle="modal" data-target="#profilename">
                      {/*<i className="fa fa-pencil"></i>*/}
                    </Link>
                  </h4>
                  <p className="m-b15">Merhaba {user.name}.</p>
                  {/*<ul className="clearfix">
                    <li>
                      <i className="ti-location-pin"></i> Ataşehir, İstanbul
                    </li>
                    <li>
                      <i className="ti-mobile"></i> {user.phone}
                    </li>
                    <li>
                      <i className="ti-briefcase"></i> Alıcı Modu
                    </li>
                    <li>
                      <i className="ti-email"></i> {user.email}
                    </li>
                  </ul>*/}
                </div>
              </div>
            </div>
            {/*<div className="col-lg-4 col-md-5">
              <Link to={""}>
                <div className="pending-info text-white p-a25">
                  <h5>Doğrulama Gerekli</h5>
                  <ul className="list-check secondry">
                    <li>E-Posta Doğrulama</li>
                  </ul>
                  <ul className="list-check">
                    <li>SMS Doğrulama</li>
                  </ul>
                </div>
              </Link>
            </div>*/}
          </div>
        </div>
      </div>

      {props.children}
    </LayoutV1>
  );
}

export default UserProfileLayout;
