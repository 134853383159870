import React from 'react';
import { TextField, FormLabel, RadioGroup, FormControlLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';

const AdminSettingsGeneral = (props) => {
  const { state, handleChange } = props;
  return (
    <>
      {/*<div className="d-flex mb-4 flexibleInputs">
        <div className="admin-settings-text-input-container">
          <TextField
            name="email"
            id="standard-basic"
            className="admin-settings-text-input"
            label="Email"
            onChange={handleChange}
          />
        </div>
      </div>*/}
      <div className="d-flex mb-4 flexibleInputs">
        <div className="admin-settings-text-input-container">
          <FormControl className="admin-settings-select-container">
            <InputLabel id="demo-simple-select-label">Ürün resimlerini göster</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              name="showImage"
              id="showImage"
              value={state.showImage}
              onChange={handleChange}
            >
              <MenuItem value={true}>On</MenuItem>
              <MenuItem value={false}>Off</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="d-flex mb-4 flexibleInputs">
        <div className="admin-settings-text-input-container">
          <FormControl className="admin-settings-select-container">
            <InputLabel id="demo-simple-select-label">Ödeme Tipi</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              name="paymentType"
              id="paymentType"
              multiple
              value={state.paymentType}
              onChange={handleChange}
              input={<Input />}
              // MenuProps={MenuProps}
            >
              <MenuItem value="cash">Nakit</MenuItem>
              <MenuItem value="credit">Kredi Kartı</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  );
};

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export default AdminSettingsGeneral;
