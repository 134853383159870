import axios from 'axios';

export const GET_BARCODE = 'v1/barcodes';

export function getBarcode(barcode) {
  return axios.get(`${GET_BARCODE}/${barcode}`);
}

export function getBarcodeWithRequiredData(barcode) {
  return axios.get(`${GET_BARCODE}/${barcode}/requiredData`);
}
export function getRequestWaiterFromBarcode(barcode) {
  return axios.get(`${GET_BARCODE}/${barcode}/requestWaiter`);
}
