import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as adminEndpoint from '../../_redux/adminEndpoint';

const formData = new FormData();

export function DropZone(props) {
  const [frontFile, setFrontFile] = useState(0);
  const [loading, setLoading] = useState(false);

  // test
  const onDrop = useCallback((acceptedFiles) => {
    // console.log(acceptedFiles[0]);
    props.selectedImages(acceptedFiles);
    /*let ins = acceptedFiles.length;
    setFrontFile(ins);
    for (let x = 0; x < ins; x++) {
      formData.append(`multiupload[${x}]`, acceptedFiles[x]);
    }*/
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function uploadImage() {
    setLoading(true);
  }

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p
          style={{
            height: 150,
            width: 300,
            backgroundColor: '#eeffeb',
            borderStyle: 'dotted',
            borderColor: 'black',
            borderRadius: 10,
            padding: 10,
            color: 'black',
          }}
        >
          Sürükle bırak
        </p>
      ) : (
        <p
          style={{
            height: 150,
            width: 300,
            backgroundColor: '#eeffeb',
            borderStyle: 'dotted',
            borderColor: 'gray',
            borderRadius: 10,
            padding: 10,
            color: 'black',
          }}
        >
          Sürükle bırak
        </p>
      )}
    </div>
  );
}
