import React from 'react';
import { ReactSVG } from 'react-svg';
import { Link, withRouter } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { router_category } from '../helpers';

function CategorySlider(props) {
  const { categories } = props;

  return (
    <div className="category-slider-area bg-color--grey space-pb--25 space-mb--25">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* section title */}
            <h2 className="section-title space-mt--10 space-mb--20">Kategoriler</h2>
            {/* category slider */}
            <div className="category-slider-wrapper">
              <Swiper spaceBetween={50} slidesPerView={4}>
                {categories &&
                  categories.map((category) => {
                    return (
                      <SwiperSlide key={category._id}>
                        <div
                          className="category-item swiper-slide "
                          onClick={() => props.history.push(router_category(props.deskCode, category._id))}
                        >
                          <div className="category-item__image d-flex justify-content-center align-items-center">
                            <div>
                              <ReactSVG src={require('images/food.svg')} style={{ width: 30 }} />
                            </div>
                          </div>
                          <div className="category-item__title  ">
                            <div onClick={() => props.history.push(router_category(props.deskCode, category._id))}>
                              {category.name}
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CategorySlider);
