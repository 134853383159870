import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutV1 from 'markup/Layout/LayoutV1';
import { getOrder } from 'modules/Order/_redux/orderEndpoint';
import { Backdrop } from '@material-ui/core';
import * as basketRedux from 'modules/Basket/_redux/basketRedux';
import { toast } from 'react-toastify';
import BreadcrumbsCustom from 'components/BreadcrumbsCustom';
import LayoutMargin from 'markup/Layout/LayoutMargin';
import { getGrandtotal } from 'helpers/PriceHelper';
import moment from 'moment';
import pusher from 'services/PusherService';

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantityCount: 1,
      order: {
        items: [],
      },
      loading: false,
    };
  }

  componentWillUnmount() {}

  componentDidMount() {
    /*window.scrollTo(0, 0);*/

    // console.log(prop);
    this.setState({
      ...this.state,
      loading: true,
    });
    getOrder(this.props.match.params.orderId)
      .then((res) => res.data)
      .then((result) => {
        const channel = pusher.subscribe(result.order.id);
        channel.bind('orderChanged', (e) => this.orderChanged(e));

        this.setState({
          ...this.state,
          order: result.order,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  orderChanged(val) {
    console.log(val.order);

    this.setState({
      ...this.state,
      order: val.order,
    });
    toast.success('Sipariş Değiştirildi.');
  }

  render() {
    const { order, loading } = this.state;

    if (loading && order) {
      return (
        <LayoutV1>
          <Backdrop open={true}>Yükleniyor... </Backdrop>
        </LayoutV1>
      );
    }
    return (
      <LayoutMargin>
        <BreadcrumbsCustom pageTitle="Sipariş Detayı" prevUrl={'/orders'} />

        <div className="body-wrapper ">
          <div className="product-content-description border-bottom--thick  space-pb--25">
            <div className="container">
              <div className="row">
                <div className="col-12 border-bottom">
                  <b>Sipariş Durumu</b> : {order.lastStatus}
                  <br />
                  <b>Sipariş Tarihi</b> : {moment(order.createdAt).fromNow()}
                </div>

                <div className="col-12">
                  <div style={{ fontFamily: 'courier', zoom: 0.8 }} className="py-3">
                    <div className="d-flex justify-content-between">
                      <span>
                        <b>Ürün </b>
                      </span>
                      <span>
                        <b>Adet</b>
                      </span>
                      <span>
                        <b>Toplam</b>
                      </span>
                    </div>
                  </div>
                  {order.items.map((item, index2) => (
                    <div key={index2} className="d-flex justify-content-between">
                      <span>{item.name}</span>
                      <span>{item.qty}X</span>
                      <span>
                        <b>{item.sum}</b> £
                      </span>
                    </div>
                  ))}
                </div>

                <div className="col-12 text-right mt-5">
                  <h6>Toplam : {getGrandtotal(order.items)}</h6>

                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutMargin>
    );
  }
}

const mapStateToProps = () => {
  // const itemId = ownProps.match.params.id;
  return {
    // product: state.productData.products && state.productData.products.filter((single) => single.id === itemId)[0],
    // wishlistItems: state.wishlistDatæa,
    // cartItems: state.cartData,
  };
};

export default connect(mapStateToProps, { ...basketRedux.actions })(OrderDetail);

{
  /*<div className="product-content-header-area border-bottom--thick space-pb--30">
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <h4 className="space-mt--5">Estimated</h4>
                          <p>15 Minutes</p>
                        </div>
                      </div>
                    </div>
                  </div>*/
}
