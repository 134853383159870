// import React from "react";
import history from '@history';

export function getCurrentUrl(location) {
  return location.pathname.split(/[?#]/)[0];
}
export function checkIsActive(location, url) {
  const current = getCurrentUrl(location);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  if (current.indexOf(url) > -1) {
    return true;
  }

  return false;
}

export function toCategoryDetail(categorySlug) {
  return history.push(`/kategori/${categorySlug}`);
}

export function generateCategoryDetail(categorySlug) {
  //return history.push(`/kategori/${categorySlug}`);
  return `/kategori/${categorySlug}`;
}
export function generateCityList(categorySlug) {
  return `/sehir/${categorySlug}`;
}

export function generateAdvertUrl(advertSlug) {
  return `/ilan/${advertSlug}`;
}

// Admin private routes
export function generateAdminCategoryDetail(categoryId) {
  return `/admin/categories/${categoryId}`;
}

// Demand
export function generateOnlineMenuUrl(barcodeId) {
  return `/z/${barcodeId}/company`;
}

export function router_barcode_detail(barcodeId) {
  return `/z/${barcodeId}`;
}

export function generateProductDetailUrl(barcodeId, productId) {
  return `/z/${barcodeId}/company/product/${productId}`;
}

export function router_category(barcodeId, categoryId) {
  return `/z/${barcodeId}/company/category/${categoryId}`;
}
