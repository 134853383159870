import React from 'react';
import { connect } from 'react-redux';
import * as authRedux from 'modules/Auth/_redux/authRedux';
import * as initialRedux from 'modules/Initial/_redux/initialRedux';
import AdminLayout from 'markup/Layout/AdminLayout';
import MaterialTable from 'material-table';
import { getUTCDate } from 'helpers';
import * as adminEdnpoint from 'modules/Admin/_redux/adminEndpoint';

function AdminOrders(props) {
  return (
    <AdminLayout>
      <div className="page-content bg-white">
        <MaterialTable
          title="Siparişler"
          options={{
            search: false,
            pageSize: 10,
            //toolbar: false,
          }}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} / {count}',
            },
            header: {
              actions: 'İşlemler',
            },
            body: {
              emptyDataSourceMessage: '',
              filterRow: {
                filterTooltip: 'Filtre',
              },
            },
          }}
          actions={[
            {
              icon: 'storefront',
              tooltip: 'Detay',
              onClick: (event, rowData) => {
                props.history.push(`/admin/orders/${rowData.id}`);
              },
            },
          ]}
          columns={[
            { title: 'id', field: 'ID', hidden: true },

            {
              title: 'Durum',
              field: 'approved',
              render: (rowData) => (rowData.completed ? 'OK' : 'İptal edildi'),
            },
            {
              title: 'Toplam',
              field: 'price',
              render: (rowData) => `${rowData.items[0]?.price ? rowData.items[0]?.price + ' TL' : 'Campaing'}`,
            },

            /*            {
              title: 'Üye',
              field: 'user',
              render: (rowData) => (
                <div>
                  {/!*<a
                  target="_blank"
                  href={`/admin/users/${rowData.user.id}`}
                >{`${rowData.user.name} ${rowData.user.surname}`}</a>*!/}
                </div>
              ),
            }*/ {
              title: 'Üyelik',
              field: 'createdAt',
              render: (rowData) => <span>{getUTCDate(rowData.createdAt)}</span>,
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              adminEdnpoint
                .getOrders(query.page, query.pageSize, 'desc')
                .then((res) => res.data)
                .then((result) => {
                  resolve({
                    data: result.results,
                    page: result.page - 1,
                    totalCount: result.totalResults,
                  });
                });
            })
          }
          editable={{
            isEditable: (rowData) => rowData.id,
            isDeletable: (rowData) => rowData.id,
          }}
        />
      </div>
    </AdminLayout>
  );
}

export default connect(null, {
  ...authRedux.actions,
  ...initialRedux.actions,
})(AdminOrders);
