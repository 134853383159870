import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { saveMasterImage } from '../../_redux/adminEndpoint';
import { DropZone } from '../AdminProduct/DropZone';
import { Label } from 'reactstrap';
import { toast } from 'react-toastify';
let formData = new FormData();

const CompanyMasterImage = (props) => {
  const [loading, setLoading] = useState(false);
  function onChange(ev) {
    // ev.preventDefault();
    console.log(ev[0]);
    formData.append('image', ev[0]);
  }

  function save() {
    setLoading(true);
    saveMasterImage(formData)
      .then((data) => {
        setLoading(false);
        toast.success('Image saved.');
      })
      .catch((err) => console.log({ err }));
  }

  return (
    <>
      <div className="d-flex flex-column mb-4">
        <FormControl className="admin-settings-input-container">
          <div className="admin-settings-text-input-container">
            <DropZone selectedImages={(ev) => onChange(ev)} />
          </div>
        </FormControl>
        <Label className="mt-2">
          <button className="btn btn-success" onClick={() => save()} disabled={loading}>
            Upload
          </button>
        </Label>
      </div>
    </>
  );
};

export default CompanyMasterImage;
