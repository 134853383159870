import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #fff',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default function ContactForm(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = useState({
    name: '',
    email: '',
    message: ' ',
  });

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleChange = (e) => {
    e.persist();
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post('')
      .then((result) => {
        console.log('basarili');
      })
      .catch((error) => {
        console.log('basarisiz');
      });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <div className="contact-form-header d-flex flex-column align-items-center justify-content-center pt-4 pb-4">
              <h2 id="transition-modal-title" className="m-0" style={{ color: '#fff' }}>
                Bize Ulaşın
              </h2>
              <p id="transition-modal-description" style={{ color: '#fff' }}>
                Lütfen aşağıdaki formu doldurunuz.
              </p>
            </div>
            <div className="contact-form-body p-5 pb-2">
              <div className="mb-4">
                <TextField
                  required
                  id="outlined-required"
                  label="İsim Soyisim"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  defaultValue=""
                  variant="outlined"
                  style={{ width: '100%' }}
                />
              </div>
              <div className="mb-4">
                <TextField
                  required
                  id="outlined-required"
                  label="Email Adresiniz"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  defaultValue=""
                  variant="outlined"
                  style={{ width: '100%' }}
                />
              </div>
              <div className="mb-">
                <TextField
                  id="outlined-multiline-static"
                  label="Mesajınız"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  placeholder="Lütfen iletmek istediğiniz mesajınızı yazınız."
                  variant="outlined"
                  style={{ width: '100%' }}
                />
              </div>
            </div>
            <div className="contact-form-send d-flex justify-content-center align-items-center p-4">
              <Button
                variant="contained"
                className={classes.button}
                endIcon={<Icon style={{ color: '#fff' }}>send</Icon>}
                style={{ background: '#11986E', color: '#fff' }}
                onClick={(e) => handleSubmit(e)}
              >
                Gönder
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
