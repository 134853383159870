import React, { useEffect, useState } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import LayoutV1 from 'markup/Layout/LayoutV1';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as initialRedux from 'modules/Initial/_redux/initialRedux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { router_barcode_detail } from 'helpers';
import { changeSlide } from 'react-slick/lib/utils/innerSliderUtils';
import { Link } from 'react-router-dom';
import { Icon } from '@material-ui/core';
import { getGrandtotal } from 'helpers/PriceHelper';
import { makeImageUrl } from 'helpers/PathHelper';

function Dashboard(props) {
  const [orders, setOrders] = useState([]);
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    let lastOrders = _.take(_.orderBy(props.orders, ['createdAt'], ['desc']), 3);
    setOrders(lastOrders);
  }, []);

  useEffect(() => {
    let lastOrders = _.take(_.orderBy(props.lastSessions, ['createdAt'], ['desc']), 5);
    setSessions(lastOrders);
  }, []);

  function goToCompanyFromSession(barcode) {
    props.history.push(router_barcode_detail(barcode.id));
  }

  const OrderList = ({ orders }) => (
    <div className="d-flex flex-column">
      {orders.map((order, index) => (
        <OrderItem order={order} key={order.id} />
      ))}
    </div>
  );

  const OrderItem = ({ order }) => (
    <Link className="border shadow rounded bg-white p-2 my-2  " to={`/orders/${order.id}`}>
      <div className="d-flex   align-items-center">
        <img
          src={makeImageUrl(order.company.image)}
          className="mr-2"
          style={{
            width: 60,
            height: 60,
            borderRadius: 30,
          }}
          alt=""
        />
        <div style={{ lineHeight: 1.3 }}>
          <h6>{order.company.name}</h6>
  <span>{moment(order.createdAt).fromNow()}</span>
          <div>Toplam : {getGrandtotal(order.items)} £</div>
        </div>
      </div>
    </Link>
  );

  return (
    <LayoutV1>
      <div className="page-content">
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-lg-12 search-box-container">
              {/*<SearchBox />*/}

              <div>
                <div>
                  <h2>Siparişler</h2>
                  <div>
                    <OrderList orders={orders} />
                  </div>

                  <Link className="d-flex justify-content-end" to="/orders">
                    Tüm Siparişler
                  </Link>
                </div>
                <div>
                  <div className="mb-5 mt-5">
                    <h2>Son Oturumlar</h2>
                    {sessions.map((session, index) => (
                      <div
                        key={index}
                        className="border radius-md shadow-sm p-2 my-1 d-flex justify-content-between align-items-center"
                      >
                        <div>
                          {session.company.name} - {session.barcode.name}
                          <br />
                          <span style={{ fontSize: 11 }}>{moment(session.createdAt).fromNow()}</span>
                        </div>
                        <div>
                          <button className="btn btn-primary" onClick={() => goToCompanyFromSession(session.barcode)}>
 AÇ
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <b>Yardım</b>

              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutV1>
  );
}

const mapStateToProps = (state) => ({
  lastSessions: state.initial.deskSessions,
  orders: state.orders.orders,
});

export default connect(mapStateToProps, { ...initialRedux.actions })(Dashboard);

{
  /*<div className="">
        {order.items.map((item, index) => (
          <div className="d-flex justify-content-around ">
            <div>{item.name}</div>
            <div>
              {item.qty} X {item.sum}
            </div>
          </div>
        ))}
      </div>*/
}

{
  /*
                    {orders.map((order, index) => (
                      <div className="border shadow my-3 p-2 d-flex flex-column" key={index}>
                        <div style={{ fontWeight: 'bold' }} className="d-flex justify-content-between">
                          <span>{order.company.name}</span>
                          <span>{moment(order.createdAt).fromNow()}</span>
                        </div>
                        <div style={{ fontFamily: 'courier', zoom: 0.8 }} className="py-3">
                          <div className="d-flex justify-content-between">
                            <span>
                              <b>Product / Service</b>
                            </span>
                            <span>
                              <b>Unit</b>
                            </span>
                            <span>
                              <b>Total</b>
                            </span>
                          </div>

                          {order.items.map((item, index2) => (
                            <div key={index2} className="d-flex justify-content-between">
                              <span>{item.name}</span>
                              <span>{item.qty}</span>
                              <span>
                                <b>{item.sum}</b> £
                              </span>
                            </div>
                          ))}
                        </div>

                        <div>
                          <button
                            onClick={() => toast.warning('Sorry, your order not yet arrived.')}
                            className="site-button"
                          >
                            Order Detail
                          </button>
                        </div>
                      </div>
                    ))}
*/
}
