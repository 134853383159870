import axios from 'axios';

export const DASHBOARD = 'v1/staff/dashboard';

/**
 * DASHBOARD
 */
export function getStaffDashboard() {
  return axios.get(`${DASHBOARD}`);
}
