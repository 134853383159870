import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const actionTypes = {
  SetBarcode: '[Barcode] Set Barcode Data',
  SetCompany: '[Barcode] Set Company Data',
};

const barcodeInitialState = {
  code: '',
  sessionCompany: {},
};

export const reducer = persistReducer(
  {
    storage,
    key: 'v713-codenra-barcode',
    whitelist: ['barcode'],
  },
  (state = barcodeInitialState, action) => {
    switch (action.type) {
      case actionTypes.SetBarcode: {
        const { code } = action.payload;
        return { ...state, code };
      }

      case actionTypes.SetCompany: {
        const { sessionCompany } = action.payload;
        return { ...state, sessionCompany };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  SetBarcode: (barcode) => ({
    type: actionTypes.SetBarcode,
    payload: { barcode },
  }),

  SetCompany: (sessionCompany) => ({
    type: actionTypes.SetCompany,
    payload: { sessionCompany },
  }),
};
