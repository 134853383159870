import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import AdminLayout from 'markup/Layout/AdminLayout';
import DragDropCustom from 'components/admin/DragDropCustom';
import * as adminEndpoint from 'modules/Admin/_redux/adminEndpoint';
import * as adminRedux from 'modules/Admin/_redux/adminRedux';
import { Link } from 'react-router-dom';

function AdminBarcodeCategoryCrud(props) {
  // const [category, setCategory] = useState({});
  // const [barcode, setBarcode] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);

  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    console.log({ user });

    adminEndpoint
      .getBarcodeCategory()
      .then((res) => res.data)
      .then((result) => {
        console.log({ result });
        // props.setCategory(result);
        // setBarcode(result);
      });
  }, []);

  const handleChange = (e) => {
    e.persist();

    /*
    setCategory((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
*/
  };
  /*

  const handleTitleChange = (e) => {
    e.persist();

    setCategory((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
*/

  const handleSave = () => {
    /*
    adminEndpoint
      .postCategoryDetail(props.match.params.categoryId, category)
      .then((res) => res.data.category)
      .then((result) => {
        props.setCategory(result);
        // setCategory(result);
        toast.success(`Kaydedildi.`);
      });
*/
  };
  /*
  const addFilterCategory = (e) => {
    setCategory((prevState) => ({
      ...prevState,
      filters: [...prevState.filters, e],
    }));
    setDialogVisible(false);
  };*/

  return (
    <AdminLayout>
      <div className="container pb-5">
        <h6>
          <Link
            to="/admin/barcodes"
            style={{
              borderRadius: 3,
              padding: 5,
              borderWidth: 1,
              backgroundColor: 'lightgray',
            }}
          >
            ⇦ Geri
          </Link>
        </h6>
        <h3 className="mt-5 mb-5">Oda Kategorileri</h3>

        <div className="d-flex flex-column justify-content-between">
          <br />
          <div>
            <DragDropCustom />
          </div>
        </div>

        <div className="pt-3 ">
          <button type="submit" className="site-button" onClick={handleSave}>
            Kaydet
          </button>
        </div>
      </div>
    </AdminLayout>
  );
}

export default connect(null, { ...adminRedux.actions })(AdminBarcodeCategoryCrud);
