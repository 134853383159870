import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as auth from 'modules/Auth/_redux/authRedux';
import JWTService from '@core/auth/JWTService';
import SocialBar from './parts/SocialBar';
import { useRifm } from 'rifm';

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  function adminLogin() {
    setForm({
      email: 'info@codenra.co.uk',
      password: '12121212a',
    });
  }
  function staffLogin() {
    setForm({
      email: 'infos@codenra.co.uk',
      password: '12121212a',
    });
  }

  function loginLocal(e) {
    e.preventDefault();
    setLoading(true);
    JWTService.signInWithCredential(form.email, form.password)
      .then((userInformation) => {
        // User information moved to persist state via redux.
        props.login(userInformation.user);
        props.setBalance(userInformation.balance);
        return userInformation;
      })
      .then((userInformation) => {
        if (userInformation.user.role === 'staff') {
          props.history.push(`/staff/dashboard`);
        }

        if (userInformation.user.role === 'admin') {
          props.history.push(`/admin/dashboard`);
        }
        // Redirection after login
      })
      .catch((err) => {
        // If user credential error show message
        //toast.error(err.response.data.message);
        setLoading(false);
      });
  }

  function handleChange(event) {
    event.persist();
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div className="bg-img-fix" style={{ height: '100vh' }}>
          <div className="row d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="col-md-3 bg-white z-index2 relative p-a0 content-scroll skew-section left-bottom">
              <div className="login-form style-2 col-md-12 col-xs-3">
                <div className="tab-content nav p-b30 tab ">
                  <div className="p-3">
                    <form className=" dez-form p-b30">
                      <div className="dez-separator-outer m-b5 mb-5">
                        <h5 className="form-title m-t0">Demand </h5>
                      </div>

                      <div className="form-group">
                        <input
                          name="email"
                          autoFocus
                          required
                          className="form-control "
                          value={form.email}
                          onChange={handleChange}
                          placeholder="E-Posta"
                          type="email"
                        />
                      </div>

                      <div className="form-group">
                        <input
                          name="password"
                          required=""
                          className="form-control "
                          value={form.password}
                          onChange={handleChange}
                          placeholder="Şifre"
                          type="password"
                        />
                      </div>

                      <div className="form-group text-left">
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            className="site-button dz-xs-flex m-r5 col-md-12"
                            disabled={loading || form.email === '' || form.password === ''}
                            onClick={loginLocal}
                          >
                            {loading ? 'Loading..' : 'Login'}
                          </button>
                        </div>
                      </div>
                      <SocialBar />
                    </form>
                  </div>
                </div>
                <div className="d-flex justify-content-around ">
                  <button className="btn" onClick={() => adminLogin()}>
                    Admin Login
                  </button>
                  <button className="btn" onClick={() => staffLogin()}>
                    Staff Login
                  </button>
                </div>

                <div className="bottom-footer clearfix m-t10 m-b20 row text-center">
                  <div className="col-lg-12 text-center">
                    <span>
                      <a href="https://codenra.co.uk" target="_blank" style={{ fontSize: 12 }}>
                        Powered by Codenra
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, auth.actions)(withRouter(Login));
