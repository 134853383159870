import React, { useEffect, useState } from 'react';
import LayoutV1 from 'markup/Layout/LayoutV1';
import { getBarcode, getBarcodeWithRequiredData } from '../_redux/barcodeEndpoint';
import CategorySlider from 'components/CategorySlider';
import CategoryLimitedView from 'components/CategoryLimitedView';

import { connect, shallowEqual, useSelector } from 'react-redux';
import * as initialRedux from '../../Initial/_redux/initialRedux';
import * as categoryRedux from '../../Category/_redux/categoryRedux';
import { Backdrop } from '@material-ui/core';
import { makeImageUrl } from 'helpers/PathHelper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function CompanyDetail(props) {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState('');
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);

    let deskCode = props.match.params.deskCode;
    setLoading(true);

    getBarcodeWithRequiredData(deskCode).then((res) => {
      props.setCategories(res.data.categories);
      setCompany(res.data.barcode.company.image);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <LayoutV1>
        <Backdrop open={true}>Yükleniyor...</Backdrop>
      </LayoutV1>
    );
  }
  const { categories } = props;
  return (
    <LayoutV1>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="page-content">
        <div className="container">
          <div className=" mb-3">
            <div className="col-xs-12 px-2">
              <div className="company-information">
                {loading && <Skeleton variant="rect" width={315} height={236} />}
                <img src={makeImageUrl(company)} className="rounded-xl-r" alt="" />
              </div>
            </div>

            <CategorySlider categories={categories} deskCode={props.match.params.deskCode} />

            {categories.map((category, index) => (
              <CategoryLimitedView
                category={category}
                key={index}
                deskCode={props.match.params.deskCode}
                companySettings={props.lastReadedSettings}
              />
            ))}
          </div>
        </div>
      </div>
    </LayoutV1>
  );
}

// export default CompanyDetail;
const mapStateToProps = (state) => ({
  initialRedux: state.initial.initialRedux,
  lastReadedSettings: state.initial.lastReadedSettings,
  categories: state.category.categories,
});

export default connect(mapStateToProps, { ...initialRedux.actions, ...categoryRedux.actions })(CompanyDetail);
