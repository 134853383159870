import React, { useEffect } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import LayoutV1 from 'markup/Layout/LayoutV1';
import { connect, shallowEqual, useSelector } from 'react-redux';
import * as pageEndpoint from 'modules/Page/_redux/pageEndpoint';
import * as pageRedux from 'modules/Page/_redux/pageRedux';
import ReactMarkdown from 'react-markdown';

let bnr = require('images/banner/bnr1.jpg');

function StaticPage(props) {
  // const [keyword, setKeyword] = useState("");
  // const [open, setOpen] = useState(false);
  // const [options, setOptions] = React.useState([]);
  // const loading = open && options.length === 0;

  const { page } = useSelector(
    (state) => ({
      page: state.page.pageDetail,
    }),
    shallowEqual
  );

  useEffect(() => {
    pageEndpoint
      .getPage(props.match.params.pageSlug)
      .then((res) => res.data)
      .then((result) => props.setPageDetail(result.pageDetail));

    window.scrollTo(0, 0);
  }, [props.match.params.pageSlug]);

  return (
    <LayoutV1>
      <div className="dez-bnr-inr overlay-black-middle" style={{ backgroundImage: 'url( ' + bnr + ')' }}>
        <div className="container">
          <div className="dez-bnr-inr-entry">
            <h1 className="text-white">{page.title}</h1>
          </div>
        </div>
      </div>

      <div className="content-block">
        <div className="section-full content-inner overlay-white-middle">
          <div className="container">
            <div className="row align-items-center m-b50">
              <div className="col-md-12 col-lg-12 m-b20">
                <h2 className="m-b5">{page.title}</h2>
                {/*<h3 className="fw4">We create unique experiences</h3>*/}
                <ReactMarkdown className="m-b15" children={page.body} />
                {/*<p className="m-b15">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less.
                </p>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutV1>
  );
}

export default connect(null, pageRedux.actions)(StaticPage);
