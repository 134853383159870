import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import * as cashierEndpoint from 'modules/Cashier/_redux/cashierEndpoint';
import { Rifm } from 'rifm';
import { numberFormat, dateFormat, cvcFormat } from 'helpers';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';

class PaymentCreditCardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /*name  : '',
            number: '',
            cvc   : '',
            expiry: '',

            focus: '',
            checkoutFormContent: null,
            error              : null

            */

      name: '12/25',
      number: '5890040000000016',
      cvc: '333',
      expiry: '12/25',
      focus: '',
      checkoutFormContent: null,
      error: null,
    };
  }

  payment() {
    //store.dispatch(openPace())

    cashierEndpoint
      .start3D({
        items: this.props.items,
        card: this.state,
      })
      .then((res) => res.data.iyzi)
      .then((res) => {
        if (res.status === 'success') {
          this.setState({
            ...this.state,
            checkoutFormContent: atob(res.threeDSHtmlContent),
          });
        }

        if (res.status === 'failure') {
          this.setState({
            ...this.state,
            error: res.errorMessage,
          });
        }
        //store.dispatch(closePace())
      })
      .catch((err) => console.log(err));
  }

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  };

  handleInputChange = (value, name) => {
    this.setState({ [name]: value });
  };

  closeModal = () => {
    this.props.handleClose();
    this.setState((prevState) => ({
      ...prevState,
      checkoutFormContent: null,
      error: null,
    }));
  };

  render() {
    return (
      <div style={{}}>
        <Modal
          open={this.props.show}
          onClose={() => this.closeModal()}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {!this.state.checkoutFormContent ? (
            <div className="m-50 bg-white p-a30 radius-md shadow">
              <div id="PaymentForm">
                <div className="d-flex flex-column ">
                  <div style={{ zoom: '100%' }}>
                    <Cards
                      placeholders={{ name: 'Ad Soyad' }}
                      cvc={this.state.cvc}
                      expiry={this.state.expiry}
                      focused={this.state.focus}
                      name={this.state.name}
                      number={this.state.number}
                    />
                  </div>
                  <div className="d-flex flex-column align-items-center mt-5">
                    <Rifm
                      value={this.state.name}
                      onChange={(e) => this.handleInputChange(e, 'name')}
                      accept={/./g}
                      format={(v) => v}
                      //replace={(v) => v.toUpperCase()}
                    >
                      {({ value, onChange }) => (
                        <TextField
                          type="text"
                          name="name"
                          autoComplete="cc-name"
                          margin="dense"
                          variant="outlined"
                          label="İsim"
                          onChange={(e) => onChange(e)}
                          value={value}
                          style={{ width: '100%' }}
                        />
                      )}
                    </Rifm>

                    <Rifm
                      value={this.state.number}
                      onChange={(e) => this.handleInputChange(e, 'number')}
                      format={numberFormat}
                    >
                      {({ value, onChange }) => (
                        /*<input
                          autoComplete="cc-number"
                          className="col-md-12"
                          label="Kart No"
                          name="number"
                          value={value}
                          onChange={onChange}
                          id="standard-basic"
                        />*/
                        <TextField
                          type="text"
                          name="number"
                          autoComplete="cc-number"
                          margin="dense"
                          variant="outlined"
                          label="Kart Numarası"
                          onChange={(e) => onChange(e)}
                          value={value}
                          style={{ width: '100%' }}
                        />
                      )}
                    </Rifm>

                    <div className="d-flex flex-row">
                      <Rifm
                        value={this.state.expiry}
                        onChange={(e) => this.handleInputChange(e, 'expiry')}
                        format={dateFormat}
                      >
                        {({ value, onChange }) => (
                          /* <input
                          autoComplete="cc-exp"
                          className="col-md-12"
                          label="Tarih"
                          type="text"
                          name="expiry"
                          value={value}
                          onChange={onChange}
                          id="standard-basic"
                        />*/
                          <TextField
                            autoComplete="cc-exp"
                            type="text"
                            name="expiry"
                            size="small"
                            margin="dense"
                            className="mr-1"
                            variant="outlined"
                            label="Tarih"
                            onChange={(e) => onChange(e)}
                            value={value}
                          />
                        )}
                      </Rifm>

                      <Rifm value={this.state.cvc} onChange={(e) => this.handleInputChange(e, 'cvc')} format={cvcFormat}>
                        {({ value, onChange }) => (
                          <TextField
                            autoComplete="cc-csc"
                            type="text"
                            className="ml-1"
                            name="cvc"
                            margin="dense"
                            variant="outlined"
                            label="CVC"
                            onChange={(e) => onChange(e)}
                            value={value}
                          />
                        )}
                      </Rifm>
                    </div>

                    <div className="d-flex align-items-center  flex-row">
                      {this.state.error && <div style={{ marginTop: 15, color: 'red' }}>{this.state.error}</div>}

                      <button className="btn btn-danger mt-5" onClick={() => this.closeModal()}>
                        Cancel
                      </button>

                      <button
                        disabled={!this.state.name || !this.state.number || !this.state.expiry || !this.state.cvc}
                        className="btn btn-success mt-5"
                        onClick={() => this.payment()}
                      >
                        Pay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ backgroundColor: 'white', borderRadius: 10 }} className="p-a30">
              <iframe
                title="Banka"
                srcDoc={this.state.checkoutFormContent}
                style={{ height: 425, marginTop: 10, border: 0 }}
              />
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  //items  : state.cartList.cart,
  loading: state.initial.isLoading,
});

export default connect(mapStateToProps, {})(PaymentCreditCardModal);
