import React, { useEffect, useState } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { connect, shallowEqual, useSelector } from 'react-redux';
import * as initial from 'modules/Initial/_redux/initialRedux';
import Pace from 'react-pace-progress';
import { ReactSVG } from 'react-svg';

function Header(props) {
  const [scrollPosition, setScrollPosition] = useState(0);

  const { paceLoading, isAdmin, isStaff, basketItems } = useSelector(
    (state) => ({
      paceLoading: state.initial.paceLoading,
      isAdmin: state.auth.user.role === 'admin',
      isStaff: state.auth.user.role === 'staff',
      basketItems: state.basket.cart,
    }),
    shallowEqual
  );

  useEffect(() => {}, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const handleClick = () => {
    props.history.push('/basket/index');
  };

  const handleHome = () => {
    props.history.push('/dashboard');
  };
  const handleAdmin = () => {
    props.history.push('/admin/dashboard');
  };

  const handleStaff = () => {
    props.history.push('/staff/dashboard');
  };

  const handleSearch = (e) => {
    console.log(e);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header>
      <div className="header-wrapper border-bottom">
        <div className="container space-y--15">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              {/* header logo */}
              <div className="header-logo">
                <Link to={process.env.PUBLIC_URL + '/'}>
                  <img src={require('images/brand/demandlogo.svg')} className="img-fluid" style={{ height: 30 }} alt="" />
                </Link>
              </div>
            </div>

            <div className="col-auto">
              {/* header menu trigger */}

              <button className="header-menu-trigger mx-2" onClick={(e) => handleHome(e)}>
                {/*<ReactSVG src={require('images/home.svg')} />*/}
                <i className="fa fa-home fa-2x text-black-light"></i>
              </button>

              {isAdmin && (
                <button className="header-menu-trigger mx-2" onClick={(e) => handleAdmin(e)}>
                  <i className="fa fa-dashboard fa-2x text-blue"></i>
                </button>
              )}

              {isStaff && (
                <button className="header-menu-trigger mx-2" onClick={(e) => handleStaff(e)}>
                  <i className="fa fa-dashboard fa-2x text-red"></i>
                </button>
              )}
              <button className="header-menu-trigger mx-2" onClick={(e) => handleClick(e)}>
                {/*<ReactSVG src={require('images/cart.svg')} />*/}
                <i className="fa fa-cart-arrow-down fa-2x text-black-light"></i>
                <span className="badge badge-danger" id="lblCartCount">
                  {basketItems.length}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* search keywords */}
      {/*<SearchKeywords show={false} />*/}
    </header>
  );
}

export default connect(null, { ...initial.actions })(withRouter(Header));
