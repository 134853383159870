import React, { useEffect, useState } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as initialRedux from 'modules/Initial/_redux/initialRedux';
import moment from 'moment';
import { router_barcode_detail } from 'helpers';
import _ from 'lodash';
import LayoutMargin from '../../markup/Layout/LayoutMargin';
import 'moment/locale/tr';

moment.locale('tr');

function Homepage(props) {
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    let lastSessions = _.take(_.orderBy(props.lastSessions, ['createdAt'], ['desc']), 3);
    setSessions(lastSessions);
  }, []);

  function goToCompanyFromSession(barcode) {
    props.history.push(router_barcode_detail(barcode.id));
  }

  return (
    <LayoutMargin>
      <div className="d-flex justify-content-center">
        <img src={require('images/readcode.png')} alt="" />
      </div>
      <div className="page-content">
        <div className="container">
          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-lg-12 search-box-container">
              {/*<SearchBox />*/}
              <br />
              <div className="mb-5">
                <b>Son Oturumlar</b>
                {sessions.map((session, index) => (
                  <div
                    key={index}
                    className="border radius-md shadow-sm p-2 my-1 d-flex justify-content-between align-items-center"
                  >
                    <div>
                      {session.company.name} - {session.barcode.name}
                      <br />
                      <span style={{ fontSize: 11 }}>{moment(session.createdAt).fromNow()}</span>
                    </div>
                    <div>
                      <button className="btn btn-primary" onClick={() => goToCompanyFromSession(session.barcode)}>
                        AÇ
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="border shadow d-flex flex-column align-items-center">
                <Link to="/z/60f8a9f1d63ec57766511b2b">
                  <img src={require('images/qr.png')} alt="" />
                </Link>
                <Link to="/z/60f8a9f1d63ec57766511b2b">Alan / Masa 16</Link>
              </div>

              <br />
            </div>
          </div>
        </div>
      </div>
    </LayoutMargin>
  );
}

const mapStateToProps = (state) => ({
  lastSessions: state.initial.deskSessions,
});

export default connect(mapStateToProps, { ...initialRedux.actions })(withRouter(Homepage));
