import Orders from '../views/Orders';
import OrderDetail from '../views/OrderDetail';

const OrderPublicRoute = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/orders/:orderId',
      component: OrderDetail,
    },
    {
      path: '/orders',
      component: Orders,
    },
  ],
};

export default OrderPublicRoute;
