import React, { useEffect } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import LayoutV1 from 'markup/Layout/LayoutV1';
import { connect, shallowEqual, useSelector } from 'react-redux';
import * as pageRedux from 'modules/Page/_redux/pageRedux';

let bnr = require('images/banner/bnr1.jpg');

function ChangeLoad(props) {
  return (
    <LayoutV1>
      <div className="dez-bnr-inr overlay-black-middle" style={{ backgroundImage: 'url( ' + bnr + ')' }}>
        <div className="container">
          <div className="dez-bnr-inr-entry">
            <h1 className="text-white">Değişiklik Geçmişi</h1>
          </div>
        </div>
      </div>

      <div className="content-block">
        <div className="section-full content-inner overlay-white-middle">
          <div className="container">
            <div className="row align-items-center m-b50">
              <div className="col-md-12 col-lg-12 m-b20">
                <br />
                <div style={{ fontSize: 10 }}>
                  <b>Değişiklik Geçmişi</b> <br />
                  <ul>
                    <li>Fr.Ad - admin için görünen siparişler sayfası staff için uyarlandı. 12.09</li>
                    <li>Fr.Ad - giriş paneline admin ve staff test bilgileri eklendi. 12.09</li>
                    <li>Fr.Ad - görünüm değişti, skeleton eklendi. 12.09</li>
                    <li>Fr.Ad - monitorize edilecek alan için staff paneli oluşturuldu. 11.09</li>
                    <li>Fr.Ad - footer layout eklendi, loading bug giderilecek. 04.09</li>
                    <li>Fr.Ad - ürün listelemesi material hale getirildi. 02.09</li>
                    <li>Admin - firmalara özel ana resim yükleme özelliği eklendi. 01.09</li>
                    <li>Fr.Ad - sipariş detay sayfası pusher özelliği eklendi. 01.09</li>
                    <li>Fr.Ad - sipariş detay sayfası ve sorguları tamamlandı. 31.08</li>
                    <li>Fr.Ad - arama özelliği sadece oturum açanlara gösterilecek. 26.08</li>
                    <li>Fr.Ad - ödeme adımlarındaki grafikler değiştirildi. 26.08</li>
                    <li>Fr.Ad - ödeme sayfası görünümü ve işlemleri tamamlandı. 25.08</li>
                    <li>Fr.Ad - kategori sıralama yönetimi tamamlandı. 01.08</li>
                    <li>Fr.Ad - garson talep etme servisi devreye alındı. 31.07</li>
                    <li>Front - ürünler arasında geçiş sorunu düzeltildi. 31.07</li>
                    <li>Front - resimler amazon sunucularına bağlanarak serverless hale getirildi. 31.07</li>
                    <li>Admin - tüm zamanlar satış listesi sayfası tamamlandı. 30.07</li>
                    <li>Admin - barkodlar için kategorileme ve garson bağlantıları tamamlandı. 30.07</li>
                    <li>Front - kullanıcı bekleme sayfası geliştirildi. 27.07</li>
                    <li>Devops - production yayını devreye alındı. 27.07</li>
                    <li>Admin - ürün düzenleme tamamlandı. 25.07</li>
                    <li>Development - change log eklendi. 24.07</li>
                    <li>Admin - masalar için barkod üretme sayfası eklendi. 24.07</li>
                    <li>Front - sipariş olmadan garson çağırma özelliği eklendi. 24.07</li>
                    <li>Front - ürün detayları sayfası tamamlandı. 23.07</li>
                    <li>Front - kategori detayı sayfası tamamlandı. 23.07</li>
                    <li>Front - kullanıcı siparişler sayfası tamamlandı. 23.07</li>
                  </ul>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutV1>
  );
}

export default connect(null, pageRedux.actions)(ChangeLoad);
