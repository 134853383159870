import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import FilterSidebar from '../../../FilterSidebar';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  drawerHeader: {
    fontSize: '18px',
    margin: 0,
    paddingTop: '15px',
    letterSpacing: '0.2px',
  },
});

function DrawerMenu(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const [prices, setPrices] = React.useState({
    maximumPrice: 0,
    minimumPrice: 0,
  });

  const { adverts } = props;

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const changeHandler = (e) => {
    setPrices({
      ...prices,
      [e.target.name]: e.target.value,
    });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="d-flex justify-content-center align-items-center">
        <p className={classes.drawerHeader}>Filtrele</p>
      </div>
      <hr />
      <div className="pl-2 pr-2">
        <FilterSidebar filters={props.filters} />
      </div>
      <div className="pl-2 pr-2 mr-1">
        <div className="row">
          <div className="col-6 mt-3">
            <TextField
              id="outlined-password-input"
              label="Min TL"
              name="minimumPrice"
              value={prices.minimumPrice}
              type="number"
              autoComplete="current-password"
              variant="outlined"
              size="small"
              onChange={(e) => changeHandler(e)}
            />
          </div>
          <div className="col-6 mt-3">
            <TextField
              id="outlined-password-input"
              label="Maks TL"
              name="maximumPrice"
              value={prices.maximumPrice}
              type="number"
              autoComplete="current-password"
              variant="outlined"
              size="small"
              onChange={(e) => changeHandler(e)}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="d-flex flex-column align-items-center">
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <div>{props.width <= 576 && <i className="fa fa-filter" style={{ color: '#11986E' }} />}</div>
          {adverts.length > 0 && (
            <Button style={{ fontSize: '10px' }} onClick={toggleDrawer(anchor, true)}>
              {props.width > 576 ? 'Daha Fazla' : 'Filtrele'}
            </Button>
          )}
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

const mapStateToProps = (state) => ({
  // adverts: state.advert.advertsOfCategory.results,
});

export default connect(mapStateToProps, {
  // ...advertRedux.actions,
})(DrawerMenu);
