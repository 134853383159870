import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as auth from 'modules/Auth/_redux/authRedux';
import JWTService from '@core/auth/JWTService';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

function ResetPassword(props) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    password: '',
    passwordConfirm: '',
  });
  const [query, setQuery] = useQueryParams({
    token: withDefault(StringParam, ''),
  });

  function forgotPasswordLocal(e) {
    e.preventDefault();
    setLoading(true);
    JWTService.resetPasswordRequest({
      password: form.password,
      token: query.token,
    })
      .then((userInformation) => {
        // User information moved to persist state via redux.
        props.login(userInformation.user);
        props.setBalance(userInformation.balance);
      })
      .then(() => {
        // Redirection after login
        props.history.push(`/`);
      })
      .catch((err) => {
        // If user credential error show message
        //toast.error(err.response.data.message);
        setLoading(false);
      });
  }

  function handleChange(event) {
    event.persist();
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  }

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div className="bg-img-fix" style={{ height: '100vh' }}>
          <div className="row d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="col-xl-4 col-lg-8 col-md-8 col-sm-12 bg-white z-index2 relative p-a0 content-scroll skew-section left-bottom">
              <div className="login-form style-2">
                <div className="clearfix" />
                <div className="tab-content nav p-b30 tab ">
                  <div id="login" className="tab-pane active ">
                    <form className=" dez-form p-b30">
                      <h5 className="form-title m-t0">Şifre Belirleme</h5>

                      <div className="dez-separator-outer m-b5">
                        <div className="dez-separator bg-primary style-liner" />
                      </div>

                      <div className="form-group">
                        <input
                          name="password"
                          autoFocus
                          required
                          className="form-control "
                          value={form.password}
                          onChange={handleChange}
                          placeholder="Yeni Şifre"
                          type="password"
                        />
                      </div>

                      <div className="form-group">
                        <input
                          name="passwordConfirm"
                          autoFocus
                          required
                          className="form-control"
                          value={form.passwordConfirm}
                          onChange={handleChange}
                          placeholder="Yeni Şifre Tekrarı"
                          type="password"
                        />
                      </div>
                      <p>{form.password !== form.passwordConfirm && 'Şifreler Uyuşmuyor.'} </p>

                      <div className="form-group text-left">
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            className="site-button dz-xs-flex m-r5"
                            disabled={loading || form.email === ''}
                            onClick={forgotPasswordLocal}
                          >
                            {loading ? 'Yükleniyor..' : 'Şifre Yenile'}
                          </button>
                          <span className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" checked id="check1" name="example1" />
                            <label className="custom-control-label" htmlFor="check1">
                              Beni Hatırla
                            </label>
                          </span>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                          <Link data-toggle="tab" to="/register" className="forget-pass m-l5">
                            <i className="fa fa-sign-in" /> Üye Ol
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="bottom-footer clearfix m-t10 m-b20 row text-center">
                  <div className="col-lg-12 text-center">
                    <span>
                      © Copyright by <br />
                      <i className="fa fa-heart m-lr5 text-red heart" />
                      <Link to={'/'}>Codenra</Link>
                      All rights reserved.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, auth.actions)(withRouter(ResetPassword));
