import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import AdminLayout from 'markup/Layout/AdminLayout';
import * as adminEndpoint from 'modules/Admin/_redux/adminEndpoint';
import * as adminRedux from 'modules/Admin/_redux/adminRedux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';
import { getBarcode } from 'modules/Admin/_redux/adminEndpoint';

function AdminBarcodeDetail(props) {
  const [category, setCategory] = useState({});
  const [barcode, setBarcode] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);

  useEffect(() => {
    adminEndpoint
      .getBarcode(props.match.params.barcodeId)
      .then((res) => res.data)
      .then((result) => {
        console.log({ result });
        // props.setCategory(result);
        setBarcode(result);
      });
  }, []);

  const handleChange = (e) => {
    e.persist();

    setCategory((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleTitleChange = (e) => {
    e.persist();

    setCategory((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSave = () => {
    adminEndpoint
      .postCategoryDetail(props.match.params.categoryId, category)
      .then((res) => res.data.category)
      .then((result) => {
        props.setCategory(result);
        setCategory(result);
        toast(`Kaydedildi.`);
      });
  };

  const addFilterCategory = (e) => {
    setCategory((prevState) => ({
      ...prevState,
      filters: [...prevState.filters, e],
    }));
    setDialogVisible(false);
  };

  /*if (_.isEmpty(category)) {
    return (
      <AdminLayout>
        <div>Yükleniyor</div>
      </AdminLayout>
    );
  }
*/

  const onImageCownload = () => {
    const svg = document.getElementById('QRCode');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = barcode.name;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };
  return (
    <AdminLayout>
      <div className="container pb-5">
        <h6>
          <Link
            to="/admin/barcodes"
            style={{
              borderRadius: 3,
              padding: 5,
              borderWidth: 1,
              backgroundColor: 'lightgray',
            }}
          >
            ⇦ Back
          </Link>
        </h6>
        <h3 className="mt-5 mb-5">Barcode / Zone Detail</h3>

        <div className="d-flex flex-column justify-content-between">
          <p>ID : {barcode.id}</p>
          <br />

          <p>Title : {barcode.name}</p>
          <br />

          <br />
          <div>
            <QRCode id="QRCode" value={`${process.env.REACT_APP_BARCODE_PREFIX}/${barcode.id}`} />
            <input type="button" value="Download QR" onClick={() => onImageCownload()} />
          </div>
        </div>

        <div className="pt-3 ">
          <button type="submit" className="site-button" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </AdminLayout>
  );
}

export default connect(null, { ...adminRedux.actions })(AdminBarcodeDetail);
