import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import Swiper from 'react-id-swiper';
import { ReactSVG } from 'react-svg';
import LayoutV1 from '../../../markup/Layout/LayoutV1';
import { withRouter } from 'react-router-dom';
import { getProductDetail } from '../../Initial/_redux/initialEndpoint';
import { makeImageUrl } from 'helpers/PathHelper';
import { getCustomerPrice } from 'helpers/PriceHelper';
import { Backdrop } from '@material-ui/core';
import * as basketRedux from 'modules/Basket/_redux/basketRedux';
import { toast } from 'react-toastify';

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // selectedProductColor: props.product.variation ? props.product.variation[0].color : '',
      // productStock: props.product.variation ? props.product.variation[0].stock : props.product.stock,
      quantityCount: 1,
      product: {},
    };
  }
  componentDidMount() {
    /*window.scrollTo(0, 0);*/

    // console.log(prop);
    this.setState({
      ...this.state,
      loading: true,
    });
    getProductDetail(this.props.match.params.productId)
      .then((res) => res.data)
      .then((result) => {
        this.setState({
          ...this.state,
          product: result.product,
          loading: false,
        });
      });
  }

  addToCart = (product) => {
    this.props.AddItemToBasket({ ...product, qty: 1 });
    toast.warning('Sepete Eklendi.');
  };

  getProductCartQuantity = () => {
    console.log('asd');
  };

  getDiscountPrice = () => {
    console.log('asd');
  };

  render() {
    const { cartItems, lastReadedSettings } = { ...this.props };
    const { selectedProductColor, productStock, quantityCount, product, loading } = this.state;

    // const wishlistItem = wishlistItems.filter((wishlistItem) => wishlistItem.id === product.id)[0];

    const productCartQty = this.getProductCartQuantity(cartItems, product, selectedProductColor);
    if (loading) {
      return (
        <LayoutV1>
          <Backdrop open={true}>Yükleniyor... </Backdrop>
        </LayoutV1>
      );
    }
    return (
      <LayoutV1>
        <div className="body-wrapper space-pt--70 space-pb--120">
          {/*====================  product image slider ====================*/}
          <div className="product-image-slider-wrapper ">
            <div className="product-image-single swiper-slide">
              {lastReadedSettings?.showImage && product.image && (
                <img src={makeImageUrl(product.image)} width={200} className="" alt="" />
              )}
            </div>
          </div>

          {/* product content header */}
          <div className="product-content-header-area border-bottom--thick space-pb--30">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="product-content-header">
                    <div className="product-content-header__main-info">
                      <h3 className="title">{product.name}</h3>
                      <div className="price">
                        {product.discount && product.discount > 0 ? (
                          <Fragment>
                            <span className="main-price mr-1">{`£${product.price}`}</span>
                            <span className="discounted-price">{`£${getCustomerPrice(
                              product.price,
                              product.discount
                            )}`}</span>
                          </Fragment>
                        ) : (
                          <span className="discounted-price">{`£${product.price}`}</span>
                        )}
                      </div>
                      {/*<div className="rating">
                        {product.rating > 1 ? (
                          <Fragment>
                            <ul className="rating__stars"><Rating ratingValue={product.rating} /></ul>
                            <span className="rating__text">{product.ratingCount}asd</span>
                          </Fragment>
                        ) : (
                          ''
                        )}
                      </div>*/}
                    </div>
                    <div className="product-content-header__wishlist-info text-center">
                      <ReactSVG src={process.env.PUBLIC_URL + '/assets/img/icons/heart-dark.svg'} />
                      <span className="count">{product.wishlistCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* product content description */}
          <div className="product-content-description border-bottom--thick  space-pb--25">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <p className="space-mb--25">{product.shortDescription}</p>
                  <h4 className="space-mb--5">Tahmini Teslimat</h4>
                  <p>15 Dakika</p>
                </div>
              </div>
            </div>
          </div>

          {/* product content description */}
          <div className="product-content-description space-pt--25 space-pb--25">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h4 className="space-mb--5">Detaylar</h4>
                  <p>{product.body}</p>
                </div>
              </div>
            </div>
          </div>
          {/* shop product button */}
          <div className="shop-product-button">
            <button className="back" onClick={() => this.props.history.goBack()}>
              GERİ
            </button>
            <button
              className="successcodenra"
              onClick={() => this.addToCart(product)}
              disabled={productCartQty >= productStock}
            >
              {productCartQty >= productStock ? 'STOKTA BULUNMAMAKTA' : 'SEPETE EKLE'}
            </button>
          </div>
        </div>
      </LayoutV1>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const itemId = ownProps.match.params.id;
  return {
    // product: state.productData.products && state.productData.products.filter((single) => single.id === itemId)[0],
    // wishlistItems: state.wishlistData,
    // cartItems: state.cartData,
    lastReadedSettings: state.initial.lastReadedSettings,
  };
};

export default connect(mapStateToProps, { ...basketRedux.actions })(ProductDetail);
