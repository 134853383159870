import React from 'react';
import { Link } from 'react-router-dom';
import { generateAdvertUrl } from '../../../../helpers';

const AdminUserProvider = (props) => {
  const { provider } = props;
  return (
    <div className="row">
      <div style={{ width: '100%', overflow: 'auto' }}>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Hash ID</th>
              <th scope="col">Provider</th>
              <th scope="col">Provider ID</th>
              <th scope="col">P Açma Tarihi</th>
            </tr>
          </thead>
          <tbody>
            {provider.map((item, index) => (
              <tr>
                <th scope="row">{index + 1}</th>
                <th>{item._id}</th>
                <th>{item.provider}</th>
                <th>{item.providerId}</th>
                <th scope="row">{item.createdAt}</th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminUserProvider;
