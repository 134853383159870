import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { getCustomerPrice } from 'helpers/PriceHelper';

export const actionTypes = {
  AddItem: '[Basket] Set Basket Data',
  DecrementItem: '[Basket] Decrement Basket Item',
  RemoveItem: '[Basket] Remove Basket Item',
  Clean: '[Basket] Remove All Item in Basket',
};

const barcodeInitialState = {
  cart: [],
};

export const reducer = persistReducer(
  {
    storage,
    key: 'v713-codenra-basket',
    // whitelist: ['basket'],
  },
  (state = barcodeInitialState, action) => {
    switch (action.type) {
      case actionTypes.AddItem: {
        const productId = action.payload.product._id;

        // If there are was productId and selectedSize true, run reducer.
        if (state.cart.findIndex((prod) => prod._id === productId && prod.selectedSize === action.size) !== -1) {
          const cart = state.cart.reduce((cartAcc, product) => {
            if (product._id === productId) {
              cartAcc.push({
                ...product,
                qty: product.qty + 1,
                sum: getCustomerPrice(product.price, product.discount) * (product.qty + 1),
              });
            } else {
              cartAcc.push(product);
            }

            return cartAcc;
          }, []);

          return { ...state, cart };
        } else {
          //console.log((action.product.price - (action.product.price * action.product.discount / 100)) * action.qty)
          console.log(action.payload.product.price);
          return {
            ...state,
            cart: [
              ...state.cart,
              {
                ...action.payload.product,
                // selectedSize: action.size,
                qty: 1,
                sum:
                  getCustomerPrice(action.payload.product.price, action.payload.product.discount) *
                  action.payload.product.qty,
              },
            ],
          };
        }
      }

      case actionTypes.DecrementItem:
        const decItem = {
          ...state.cart[action.payload.key],
          qty: state.cart[action.payload.key].qty - 1,
        };

        const cart = state.cart.map((item, index) => (index === action.payload.key ? decItem : item));

        return {
          ...state,
          cart,
        };

      case actionTypes.RemoveItem:
        const decItem2 = {
          ...state.cart[action.payload.key],
          qty: state.cart[action.payload.key].qty - 1,
        };

        const cart2 = state.cart.filter((item, index) => index !== action.payload.key);

        return {
          ...state,
          cart: cart2,
        };

      case actionTypes.Clean:
        return {
          ...state,
          cart: [],
        };

      default:
        return state;
    }
  }
);

export const actions = {
  AddItemToBasket: (product) => ({
    type: actionTypes.AddItem,
    payload: { product },
  }),
  DecrementQtyButton: (key) => ({
    type: actionTypes.DecrementItem,
    payload: { key },
  }),
  RemoveItemAction: (key) => ({
    type: actionTypes.RemoveItem,
    payload: { key },
  }),
  Clean: () => ({
    type: actionTypes.Clean,
  }),
};
