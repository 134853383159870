import React, { useState, useEffect } from 'react';
import AdminLayout from '../../../markup/Layout/AdminLayout';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '68%',
  },
}));

const advert = {
  id: 1,
  title: 'deneme baslik',
  name: 'deneme',
};

const showCards = () => {
  return (
    <div className="items">
      <div className="icon-wrapper">
        <div style={{ width: '100%' }}>
          <p id="icon-wrapper-title" style={{ margin: 0 }}>
            {advert.title}
          </p>
        </div>
        <div className="job-post-info">
          <ul style={{ padding: '10px 5px' }}>
            <li>
              <i className="fa fa-map-marker"></i> Kadıköy, Istanbul
            </li>
            <li>
              <i className="fa fa-bookmark-o"></i> Kategori
            </li>
            <li>
              <i className="fa fa-clock-o"></i>1 ay önce
            </li>
          </ul>
          <div className="d-flex">
            <PersonOutlineOutlinedIcon style={{ color: '#129663' }} />
            <p id="job-post-info-content">Kullanici deneme</p>
          </div>
        </div>
      </div>
      <div className="project-name">
        <Link id="linkCard" to={`/ilan/${advert.id}/detay`}>
          Detay
          <span style={{ position: 'relative', top: '7px' }}>
            <ArrowForwardIcon />
          </span>
        </Link>
      </div>
    </div>
  );
};

const AdminSearch = () => {
  const classes = useStyles();
  const [searchItem, setSearchItem] = useState(null);

  useEffect(() => {
    const input = document.getElementById('input-with-icon-adornment');
    input.addEventListener('keydown', () => {
      //Arama sonucu buradan çekilecek
    });
  }, [searchItem]);

  const onChangeHandler = (e) => {
    setSearchItem(e.target.value);
  };

  return (
    <AdminLayout>
      <div className="page-content bg-white">
        <div className="d-flex flex-row flex-wrap justify-content-center p-5" id="admin-search-input-container">
          <Input
            id="input-with-icon-adornment"
            name="searchItem"
            className={classes.root}
            value={searchItem}
            onChange={onChangeHandler}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
          <Button
            id="admin-search-button"
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<Icon>send</Icon>}
          >
            Ara
          </Button>
        </div>
        {/*<h2 style={{ textAlign: "center" }}>Sonuç bulunamadı.</h2>*/}
        <div className="row mb-5">
          <div className="col-sm-12">
            <div className="row post-job-bx browse-job">
              <div className="containerOfCards">
                {/*{showCards()}*/}
                {/* {showCards()}
                {showCards()}
                {showCards()}
                {showCards()}*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminSearch;
