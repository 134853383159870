import BarcodeDetail from '../views/BarcodeDetail';
import CompanyDetail from '../views/CompanyDetail';

const BarcodePublicRoute = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/z/:deskCode/company',
      component: CompanyDetail,
    },
    {
      path: '/z/:deskCode',
      component: BarcodeDetail,
    },
  ],
};

export default BarcodePublicRoute;
