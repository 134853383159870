import React, { useEffect } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';

import { connect } from 'react-redux';
import * as auth from 'modules/Auth/_redux/authRedux';
import LayoutMargin from './LayoutMargin';

function LoadingLayout(props) {
  // const { user } = useSelector(
  //   (state) => ({
  //     user: state.auth.user,
  //   }),
  //   shallowEqual
  // );

  function exit() {
    props.logout();
  }
  useEffect(() => {}, []);

  return (
    <LayoutMargin>
      <div className="overlay-black-dark profile-edit ">
        <div className="container d-flex justify-content-between"></div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12 ">{props.children}</div>
        </div>
      </div>
    </LayoutMargin>
  );
}

export default connect(auth.reducer, auth.actions)(LoadingLayout);
