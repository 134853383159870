export function convertPrice(price) {
  return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function getCustomerPrice(price, discount) {
  let number = price - discount;
  return number.toFixed(2);
  // return (price - (price / 100) * discount).toFixed(2);
}

export function getCustomerDiscount(discount) {
  return discount.toFixed(0);
}

export function getTax(total) {
  return ((total / 100) * 18).toFixed(2);
}

export function getTotalWithDecimal(total) {
  return total.toFixed(2);
}

export function getReservePrice(price, discount) {
  return (price + (price / 100) * discount).toFixed(2);
}

/* Sum cart items for total from baskets. */
export function getGrandtotal(cartItems) {
  let total = 0;
  for (let i = 0; i < cartItems.length; i++) {
    console.log({
      name: cartItems[i].name,
      price: cartItems[i].price,
      qty: cartItems[i].qty,
      discount: cartItems[i].discount,
    });
    total += (cartItems[i].price - cartItems[i].discount) * cartItems[i].qty;
  }

  return total;
  // return ((total / 100) * 18).toFixed(2);
  return 0;
}
