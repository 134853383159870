import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import AdminLayout from 'markup/Layout/AdminLayout';
import * as adminEndpoint from 'modules/Admin/_redux/adminEndpoint';
import * as adminRedux from 'modules/Admin/_redux/adminRedux';
import { Link } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import _ from 'lodash';
import FilterCreateDialog from '../Dialogs/FilterCreateDialog';
import { toast } from 'react-toastify';

function AdminCategoryDetail(props) {
  const [category, setCategory] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);

  useEffect(() => {
    adminEndpoint
      .getCategoryDetail(props.match.params.categoryId)
      .then((res) => res.data.category)
      .then((result) => {
        props.setCategory(result);
        setCategory(result);
      });
  }, []);

  const handleChange = (e) => {
    e.persist();

    setCategory((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleTitleChange = (e) => {
    e.persist();

    setCategory((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSave = () => {
    adminEndpoint
      .postCategoryDetail(props.match.params.categoryId, category)
      .then((res) => res.data.category)
      .then((result) => {
        props.setCategory(result);
        setCategory(result);
        toast(`Kaydedildi.`);
      });
  };

  const addFilterCategory = (e) => {
    setCategory((prevState) => ({
      ...prevState,
      filters: [...prevState.filters, e],
    }));
    setDialogVisible(false);
  };

  if (_.isEmpty(category)) {
    return (
      <AdminLayout>
        <div>Yükleniyor</div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="container pb-5">
        <h6>
          <Link
            to="/admin/categories"
            style={{
              borderRadius: 3,
              padding: 5,
              borderWidth: 1,
              backgroundColor: 'lightgray',
            }}
          >
            ⇦ Geri git
          </Link>
        </h6>
        <h3>Kategori Düzenle</h3>

        <div className="d-flex flex-wrap ">
          <div className="flex-grow-1 p-1">
            <div className="d-flex flex-column flex-sm-row mb-4">
              <TextField
                key="asddd"
                name="name"
                value={category.name}
                defaultValue={category.name}
                style={{ flex: 1 }}
                className="pr-1"
                onChange={handleTitleChange}
              />

              <TextField
                key="asss"
                disabled
                name="slug"
                value={category.slug}
                defaultValue={category.slug}
                className="pr-1"
                style={{ flex: 1 }}
              />
            </div>
            <div className="d-flex flex-column flex-sm-row mb-4 col-md-4">
              Renk :
              <TextField
                key="asd09"
                name="color"
                value={category.color}
                defaultValue={category.color}
                style={{ flex: 1 }}
                className="pr-1"
                onChange={handleChange}
              />
              <div
                style={{
                  height: 50,
                  width: 50,
                  backgroundColor: category.color,
                }}
              >
                .
              </div>
            </div>

            <div className="d-flex flex-column flex-sm-row mb-4 col-md-4">
              Rate Değeri :
              <TextField
                key="asd019"
                name="rate"
                value={category.rate}
                defaultValue={category.rate}
                style={{ flex: 1 }}
                className="pr-1"
                onChange={handleChange}
              />
            </div>

            <div className="d-flex mb-4 ">
              <TextField
                key="aaaasd"
                name="body"
                rows={4}
                multiline
                value={category.body}
                defaultValue={category.body}
                style={{ flex: 1 }}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="flex-grow-1  p-1 ">
            <div className=" radius-md p-2 bg-green text-white">
              <h5>Filtre</h5>

              <FilterCreateDialog
                open={dialogVisible}
                dialogVisible={dialogVisible}
                addFilterCategory={(e) => addFilterCategory(e)}
                toggleCreateCategoryDialog={(e) => setDialogVisible(false)}
              />

              <div>
                {category.filters.map((item, index) => (
                  <div className="border-1 my-2 radius-md p-2 shadow-bx bg-light text-black" key={index}>
                    <div>
                      <b>Başlık</b> : {item.title}
                    </div>
                    <div>
                      <b>Filtre Tipi </b>: {item.filterType}
                    </div>
                    <div>
                      <b>Seçenekler</b>:
                      {item.values.map((item, index) => (
                        <span key={index}>{item}, </span>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              <div className="d-flex flex-row justify-content-between">
                <button className="btn btn-light" onClick={() => setDialogVisible(true)}>
                  Filtre Ekle
                </button>
              </div>
            </div>

            <div className=" radius-md p-2 bg-green text-white mt-3">
              <h5>İkon</h5>

              <div>
                <img src={category.icon} style={{ width: 50, height: 50 }} alt="" />
                <div>Kategori ikonları sabit görsel olup, dosya sistemi üzerinden değiştirilebilir.</div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-3 ">
          <button type="submit" className="site-button" onClick={handleSave}>
            Kaydet
          </button>
        </div>
      </div>
    </AdminLayout>
  );
}

export default connect(null, { ...adminRedux.actions })(AdminCategoryDetail);
