import React, { useState, useEffect } from 'react';
import AdminLayout from '../../../../markup/Layout/AdminLayout';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Tab, AppBar } from '@material-ui/core';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import AdminSettingsGeneral from './AdminSettingsGeneral';
import AdminSettingsNewPassword from './AdminSettingsNewPassword';
import * as adminEndpoint from 'modules/Admin/_redux/adminEndpoint';
import { toast } from 'react-toastify';
import CompanyMasterImage from './CompanyMasterImage';

//import AdminCategories from "../AdminCategories";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '50ch',
  },
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  select: {
    width: '100%',
  },
}));

function AdminSettings(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = useState('2');
  const [state, setState] = useState({
    showImage: false,
    paymentType: [],
  });

  useEffect(() => {
    adminEndpoint
      .getSettings(state)
      .then((res) => res.data.company)
      .then((company) => {
        setState(company.settings);
      });
  }, []);

  const handleChangeTags = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const handleSave = (event) => {
    adminEndpoint
      .saveSettings(state)
      .then((res) => res.result)
      .then((result) => {
        console.log(result);
        toast.success('Settings saved.');
      });
  };

  return (
    <>
      <AdminLayout>
        <div className="page-content bg-white">
          <div className="d-flex flex-row flex-wrap">
            <div className={classes.root}>
              <TabContext value={value}>
                <AppBar position="static" color="default">
                  <TabList onChange={handleChangeTags} aria-label="settings panel">
                    <Tab label="Genel" value="1" />
                    <Tab label="Resim Ayarları" value="2" />
                    <Tab label="Şifreler" value="3" />
                  </TabList>
                </AppBar>
                <TabPanel value="1">
                  <AdminSettingsGeneral handleChange={handleChange} state={state} classes={classes} theme={theme} />
                </TabPanel>
                <TabPanel value="2">
                  <CompanyMasterImage handleChange={handleChange} state={state} />
                </TabPanel>
                <TabPanel value="3">
                  <AdminSettingsNewPassword handleChange={handleChange} state={state} />
                </TabPanel>
              </TabContext>
            </div>
          </div>
          <div style={{ padding: '24px' }}>
            {value !== '2' && (
              <button className="site-button" type="submit" onClick={() => handleSave()}>
                Kaydet
              </button>
            )}
          </div>
        </div>
      </AdminLayout>
    </>
  );
}

export default AdminSettings;
