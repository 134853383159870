import ProductDetail from '../views/ProductDetail';

const ProductPublicRoute = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/z/:deskCode/company/product/:productId',
      component: ProductDetail,
    },
  ],
};

export default ProductPublicRoute;
