import axios from 'axios';

export const GET_CATEGORIES = 'v1/categories';

export function getCategory(categorySlug, filters, query) {
  return axios.post(`${GET_CATEGORIES}/${categorySlug}?page=${query}&sortBy=createdAt:${query}&relation=category`, {
    filters,
  });
}

export function getCategoryProduct(barcodeId, categoryId) {
  return axios.get(`${GET_CATEGORIES}/${categoryId}`);
}

export function getCategories() {
  return axios.get(GET_CATEGORIES);
}
