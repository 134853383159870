import React, { useEffect, useState } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import * as authRedux from 'modules/Auth/_redux/authRedux';
import * as initialRedux from 'modules/Initial/_redux/initialRedux';
import AdminLayout from 'markup/Layout/AdminLayout';
import Box from '@material-ui/core/Box';
import * as adminEndpoint from '../_redux/adminEndpoint';
import { toast } from 'react-toastify';
import moment from 'moment';
import pusher from 'services/PusherService';
import 'moment/locale/tr'; // Türkçe dil paketini dahil ediyoruz

moment.locale('tr');
let alarm = require('images/alarm_tone.mp3');

function AdminDashboard(props) {
  const [dashboard, setDashboard] = useState({
    orders: [],
    ordersCount: 0,
    priceTotal: 0,
    usersCount: 0,
    boughtsCount: 0,
  });

  const [call, setCall] = useState([]);

  const [audio] = useState(new Audio(alarm));
  const [playing, setPlaying] = useState(true);
  const [backdrop, setBackdrop] = useState(false);

  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  useEffect(() => {
    loadOrders();
  }, []);

  function loadOrders() {
    setBackdrop(true);
    adminEndpoint.getDashboard().then((res) => {
      setBackdrop(false);

      setDashboard({
        orders: res.data.orders,
        usersCount: res.data.usersCount,
        boughtsCount: res.data.boughtsCount,
        priceTotal: res.data.priceTotal,
      });
    });
  }

  useEffect(() => {
    const channel = pusher.subscribe(user.company);
    channel.bind('orderCompleted', orderCompleted);
    channel.bind('requestWaiterChannel', requestWaiterChannel);
    return () => {
      channel.unbind_all();
    };
  }, []);

  function orderCompleted(val) {
    setPlaying(true);
    loadOrders();
    toast.success('Yeni Sipariş!');

    audio.play().then((res) => console.log('played', res));
  }

  function requestWaiterChannel(val) {
    toast.success(`${val.companyBarcode.name} need to service.`, { autoClose: 910000 });
    setCall((prevProps) => prevProps.concat({ ...val.companyBarcode, callDate: moment.now() }));
    audio.play().then((res) => console.log('played', res));
  }

  function approveOrder(orderId) {
    adminEndpoint.approveOrderEndpoint(orderId).then((res) => {
      loadOrders();
      toast.success('Sipariş kabul edildi.');
    });
  }

  const dashoardWidgets = (total, name) => {
    return (
      <Box className="dashboardADM-widgets-box shadow radius-md" p={1} mb={5}>
        <div className="row">
          <div className="col-xs-12 col-md-12 dashboardADM-widgets-contents-container">
            <div className="col-xs-12 col-md-12">
              <label className="dashboardADM-widgets-number">{total}</label>
            </div>
            <div className="col-xs-12 col-md-12" style={{ padding: 0 }}>
              <label className="dashboardADM-widgets-header">{name}</label>
            </div>
          </div>
        </div>
      </Box>
    );
  };
  const Order = ({ id, zone, date, items, lastStatus, createdAt, company }) => {
    return (
      <div className="border radius-sm shadow p-3 m-2 h-auto">
        <div className="d-flex justify-content-between">
          <span>Oda</span>
          <h5>{id}</h5>
        </div>
        <div className="d-flex justify-content-between">
          <span>Tarih</span>
          <h5>{moment(createdAt).fromNow()}</h5>
        </div>
        <div className="d-flex justify-content-between">
          <span>Müşteri</span>
          <h5>user15684</h5>
        </div>
        <div className="d-flex justify-content-between">
          <span>Durum</span>
          <h5>{lastStatus}</h5>
        </div>

        <div className="border rounded p-1">
          {items.map((product, index) => (
            <div key={index} className="d-flex justify-content-between">
              <div>
                {product.name} - X{product.qty}
              </div>
            </div>
          ))}
        </div>

        <div className="d-flex justify-content-between mt-3">
          {lastStatus === 'new' && (
            <button className="btn btn-success" onClick={() => approveOrder(id)}>
              Kabul Et
            </button>
          )}
          {lastStatus === 'new' && (
            <button className="btn btn-warning" onClick={() => alert('Hold')}>
              Beklemede
            </button>
          )}
          {lastStatus === 'approved' && (
            <button className="btn btn-primary" onClick={() => alert('Arrived')}>
              Teslim Edildi
            </button>
          )}
          {lastStatus === 'arrived' && (
            <button className="btn btn-danger" onClick={() => alert('Archive')}>
              Arşivlendi
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <AdminLayout>
      <div className="row">
        <div className="col-md-12 d-flex flex-row" style={{ zoom: 0.7 }}>
          {call.map((desk, index) => dashoardWidgets(desk.name, moment(desk.callDate).fromNow()))}
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <h3>Yeni Talepler</h3>
          {dashboard.orders
            .filter((item) => item.lastStatus === 'new')
            .map((order, index) => (
              <Order key={index} {...order} />
            ))}
        </div>
        <div className="col-md-4">
          <h3>Kabul Edilenler</h3>
          {dashboard.orders
            .filter((item) => item.lastStatus === 'approved')
            .map((order, index) => (
              <Order key={index} {...order} />
            ))}
        </div>
        <div className="col-md-4" style={{ opacity: 0.5 }}>
          <h3>Teslim Edilenler</h3>

          {dashboard.orders
            .filter((item) => item.lastStatus === 'arrived')
            .map((order, index) => (
              <Order key={index} {...order} />
            ))}
        </div>
      </div>
    </AdminLayout>
  );
}

export default connect(null, {
  ...authRedux.actions,
  ...initialRedux.actions,
})(AdminDashboard);
