import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import AdminLayout from 'markup/Layout/AdminLayout';
import { convertToSlug, generateAdminCategoryDetail } from 'helpers';
import * as authRedux from 'modules/Auth/_redux/authRedux';
import * as initialRedux from 'modules/Initial/_redux/initialRedux';
import * as adminEndpoint from 'modules/Admin/_redux/adminEndpoint';
import CategoryCreateDialog from '../Dialogs/CategoryCreateDialog';
import { Link } from 'react-router-dom';

const formData = new FormData();

function AdminCategories(props) {
  const [createDialog, setCreateDialog] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // let advertId = props.match.params.advertId;

    loadCategoryTree();
  }, []);

  const deleteCategory = (categoryId) => {
    adminEndpoint
      .deleteCategory(`${categoryId}`)
      .then((res) => res.data)
      .then((result) => {
        setCategories(result);
      });
  };

  const loadCategoryTree = () => {
    adminEndpoint
      .getCategories()
      .then((res) => res.data)
      .then((result) => {
        console.log('res', result);
        setCategories(result);
      });
  };

  const createCategory = (e) => {
    e.preventDefault();
    adminEndpoint.createCategory(formData).then((result) => {
      // loadCategoryTree();
      setCategories(result.data);
      toast.success('Eklendi');
      clearFormDataObject();
      setCreateDialog(false);
    });
  };

  const imageSelect = (event) => {
    let image = event.target.files[0];
    formData.append('image', image);
  };

  const clearFormDataObject = () => {
    for (let key of formData.keys()) {
      formData.delete(key);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name === 'name') {
      formData.set('slug', convertToSlug(e.target.value));
      formData.set(e.target.name, e.target.value);
    } else {
      formData.set(e.target.name, e.target.value);
    }
    return formData;
  };

  const toggleCreateCategoryDialog = () => {
    setCreateDialog((e) => !e);
  };

  return (
    <AdminLayout>
      <div className="page-content ">
        <div className="d-flex flex-column flex-wrap">
          {categories.map((lev1) => (
            <div className="mx-2 p-3 my-3 shadow rounded " key={lev1._id} style={{ height: 175 }}>
              <div className="d-flex justify-content-between">
                <h3>{lev1.name}</h3>
                <div className="">
                  <button onClick={() => deleteCategory(lev1._id)} className="bg-red radius-sm border-0 p-1">
                    <i className="fa fa-trash text-white" />
                  </button>

                  <Link to={generateAdminCategoryDetail(lev1._id)} className="bg-blue p-1 radius-sm p-2 m-1">
                    <i className="fa fa-list text-white" />
                  </Link>
                </div>
              </div>
              <div className=" mb-1">
                {lev1.hierarchy.map((lev2, index) => (
                  <div key={index}>
                    &rarr; {lev2.name} &nbsp;
                    <button onClick={() => deleteCategory(lev2._id)}>
                      <i className="fa fa-trash" />
                    </button>
                    <div className="mb-1">
                      {lev2.hierarchy.map((lev3, index) => (
                        <div key={index}>
                          &nbsp; &#x21B3; {lev3.name}
                          <button onClick={() => deleteCategory(lev3._id)}>
                            <i className="fa fa-trash " />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="mx-3 p-3 shadow rounded bg-green mt-5">
          <div className="pb-1">
            <button className="btn " onClick={() => toggleCreateCategoryDialog()}>
              Yeni Kategori
              <i className="fa fa-plus m-1" />
            </button>

            <CategoryCreateDialog
              {...props}
              open={createDialog}
              viaFormData={formData}
              levelOneCategories={categories}
              toggleCreateCategoryDialog={() => toggleCreateCategoryDialog()}
              handleFieldChange={(e) => handleChange(e)}
              handleImageSelect={(e) => imageSelect(e)}
              postCreateCategory={(e) => createCategory(e)}
            />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}

export default connect(null, {
  ...authRedux.actions,
  ...initialRedux.actions,
})(AdminCategories);
