import React, { useEffect } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';
import * as auth from 'modules/Auth/_redux/authRedux';
import LayoutMargin from './LayoutMargin';

const array = [
  { id: 1, title: 'Panel', link: 'dashboard', icon: 'home' },
  { id: 5, title: 'Odalar', link: 'barcodes', icon: 'branding_watermark' },
  { id: 2, title: 'Kategoriler', link: 'categories', icon: 'category' },
  { id: 3, title: 'Ürünler', link: 'products', icon: 'inventory_2' },
  { id: 4, title: 'Moderatörler', link: 'employees', icon: 'person' },
  { id: 4, title: 'Siparişler', link: 'orders', icon: 'assessment' },
  { id: 4, title: 'Ayarlar', link: 'settings', icon: 'settings' },
];

function AdminLayout(props) {
  function exit() {
    props.logout();
  }
  useEffect(() => {}, []);

  return (
    <LayoutMargin>
      <div className="overlay-black-dark profile-edit ">
        <div className="container d-flex justify-content-between">
          <div className="d-flex flex-row  flex-wrap ">
            {array.map((item, index) => (
              <div key={index} className="p-1 m-1">
                <Link to={`/admin/${item.link}`}>
                  <div
                    className="border bg-white p-3 radius-md d-flex flex-column align-items-center "
                    style={{ width: 70, height: 60, fontSize: 11 }}
                  >
                    <Icon>{item.icon}</Icon>
                    {item.title}
                  </div>
                </Link>
              </div>
            ))}
            <div key={654654654} className="p-1 m-1">
              <div onClick={() => exit()}>
                <div
                  className="border bg-white p-3 radius-md d-flex flex-column align-items-center "
                  style={{ width: 70, height: 60, fontSize: 11 }}
                >
                  <Icon>logout</Icon>
                  Çıkış
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12 mt-5">{props.children}</div>
        </div>
      </div>
    </LayoutMargin>
  );
}

export default connect(auth.reducer, auth.actions)(AdminLayout);
