import React, { Fragment, useEffect } from 'react';
import { generateProductDetailUrl } from '../helpers';
import { makeImageUrl } from '../helpers/PathHelper';
import { getCustomerPrice } from '../helpers/PriceHelper';
import { ReactSVG } from 'react-svg';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as basketRedux from '../modules/Basket/_redux/basketRedux';

function ProductItem(props) {
  const { product } = props;
  useEffect(() => {
    // console.log(props.companySettings.showImage);
  }, []);

  function addToCart(item) {
    // console.log(item);
    props.AddItemToBasket({ ...item, qty: 1 });
    toast(`Added to Cart.`, { type: 'warning' });
  }

  return (
    <div className={props.companySettings?.showImage ? 'col-6' : 'col-12'} key={product._id}>
      <div className="featured-product space-mb--15 border bg-white shadow">
        <div onClick={() => props.history.push(generateProductDetailUrl(props.match.params.deskCode, product._id))}>
          <div className="featured-product__image">
            {/*<Link to={process.env.PUBLIC_URL + `/product/${product._id}`}>
                            </Link>*/}
            {props.companySettings?.showImage && (
              <img
                src={makeImageUrl(product.image)}
                // onClick={() => props.history.push(`/product/${product.id}`)}
                className="img-fluid rounded"
                alt=""
              />
            )}
          </div>
          {product.name}
        </div>
        <div className="featured-product__content">
          <div className="price">
            {product.discount && product.discount > 0 ? (
              <Fragment>
                <span className="main-price mr-1">{`£${product.price}`}</span>
                <span className="discounted-price font-14 ">{`£${getCustomerPrice(product.price, product.discount)}`}</span>
              </Fragment>
            ) : (
              <span className="discounted-price">{`$${product.price}`}</span>
            )}
          </div>
          <div className="icon">
            <button
              // className={wishlistItem !== undefined ? 'active' : ''}
              // disabled={wishlistItem !== undefined}
              onClick={() => addToCart(product)}
            >
              <ReactSVG src={require('images/cart.svg')} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { ...basketRedux.actions })(withRouter(ProductItem));

// export default withRouter(ProductItem);
