import PropTypes from 'prop-types';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Icon } from '@material-ui/core';

const BreadcrumbsCustom = ({ pageTitle, prevUrl, history }) => {
  return (
    <div className="breadcrumb-area bg-color--grey space-y--15">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-3">
            <button onClick={() => history.goBack()} className="back-link d-flex justify-content-center">
              <Icon>arrow_left</Icon> Geri
            </button>
          </div>
          <div className="col-6">
            <h4 className="page-title text-center">{pageTitle}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

BreadcrumbsCustom.propTypes = {
  pageTitle: PropTypes.string,
  prevUrl: PropTypes.string,
};

export default withRouter(BreadcrumbsCustom);
