import React, { useEffect, useState } from 'react';
import * as adminEndpoint from 'modules/Admin/_redux/adminEndpoint';
import AdminLayout from 'markup/Layout/AdminLayout';
import { Backdrop, Checkbox, FormControlLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { convertToSlug } from 'helpers';
import { DropZone } from './DropZone';
import { toast } from 'react-toastify';
import { makeImageUrl } from 'helpers/PathHelper';
import { updateProductRequest } from 'modules/Admin/_redux/adminEndpoint';
import LayoutMargin from '../../../../markup/Layout/LayoutMargin';
import LoadingLayout from '../../../../markup/Layout/LoadingLayout';
// import AddProductForm from './AddProductForm';

let productForm = new FormData();
const initialState = {
  name: '',
  price: '',
  body: '',
  saleActive: false,
};

function EditProduct(props) {
  const [product, setProduct] = useState(initialState);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let productId = props.match.params.productId;
    adminEndpoint
      .getProductDetail(productId)
      .then((res) => res.data.product)
      .then((result) => {
        console.log(result);
        setProduct({ ...result, category: result.category._id });
      });
  }, []);

  useEffect(() => {
    adminEndpoint
      .getCategories()
      .then((res) => res.data)
      .then((result) => {
        // console.log(result);
        setCategories(result);
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProduct((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
    // productForm.append(name, value);
  };
  const handleCategoryChange = (event) => {
    const { name, value } = event.target;
    console.log({ name, value });
    setProduct((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
    // productForm.append(name, value);
  };

  const changeActiveSwitch = (e) => {
    const { name, checked } = e.target;
    setProduct((prevProps) => ({
      ...prevProps,
      [name]: checked,
    }));
  };

  const selectedImages = (e) => {
    // we are selection only first image, multi image will support. maybe.
    // productForm.delete('image');
    productForm.append('image', e[0]);
    console.log(e[0]);
  };

  const handleSave = () => {
    setLoading(true);
    // We will mapping for local state then transfers to
    // FormData Object with values. After that we will clean.
    Object.entries(product).map((r) => {
      if (r[0] !== 'image') {
        productForm.delete(r[0]);
        productForm.append(r[0], r[1]);
      }
    });

    // We will sent FormData object for image upload
    let productId = props.match.params.productId;

    // console.log(productForm.getAll());

    adminEndpoint
      .updateProductRequest(productId, productForm)
      .then((res) => res.data.product)
      .then((result) => {
        toast.success('Updated');
        // Clear form data. this important for duplicated contents.
        productForm = new FormData();
        console.log(result);
        setLoading(false);
        setProduct(result);
      })
      .catch((res) => {
        setLoading(false);
      });
  };

  if (!product._id || loading) {
    return (
      <AdminLayout>
        <Backdrop open>Loading...</Backdrop>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <Backdrop open={true}>Loading...</Backdrop>
      <div className="container" style={{ height: 800 }}>
        <h6 className="mb-5">
          <Link
            to="/admin/products"
            style={{
              borderRadius: 3,
              padding: 5,
              borderWidth: 1,
              backgroundColor: 'lightgray',
            }}
          >
            ⇦ Liste
          </Link>
        </h6>

        <div className="d-flex flex-wrap ">
          <div className="flex-grow-1 p-1">
            <div className="d-flex flex-column flex-sm-row mb-4">
              <TextField
                label="Ürün Adı"
                name="name"
                value={product.name}
                // defaultValue={product.name}
                style={{ flex: 1 }}
                className="pr-1"
                onChange={handleChange}
              />
            </div>
            <div className="d-flex mb-4">
              <TextField
                label="Fiyat"
                name="price"
                value={product.price}
                // defaultValue={product.price}
                style={{ flex: 1 }}
                className="pr-1"
                onChange={handleChange}
              />

              <TextField
                label="İndirim"
                name="discount"
                value={product.discount}
                defaultValue={0}
                style={{ flex: 1 }}
                className="pr-1"
                onChange={handleChange}
              />
            </div>

            <div className="d-flex mb-4 ">
              <Select
                id="demo-simple-select-helper"
                name="category"
                label="Kategori"
                value={product.category}
                // defaultValue={0}
                style={{ flex: 1 }}
                onChange={handleCategoryChange}
              >
                <MenuItem key={98989} value={0}>
                  Select Category
                </MenuItem>
                {categories.map((category, index) => (
                  <MenuItem key={index} value={category._id}>
                    {category.name} - {category._id}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="d-flex mb-4 ">
              <TextField
                label="Description"
                name="body"
                rows={4}
                multiline
                value={product.body}
                // defaultValue={product.body}
                style={{ flex: 1 }}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="flex-grow-1  p-1" style={{ fontSize: 11 }}>
            <div className=" radius-md p-2 bg-gray mb-3 ">
              <h5>Satış durumu</h5>

              <FormControlLabel
                control={
                  <Checkbox
                    name="saleActive"
                    checked={product.saleActive}
                    onChange={(e) => changeActiveSwitch(e)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label="Aktif / Pasif"
              />
            </div>

            <div className=" radius-md p-2 bg-green text-white">
              <h5>Resim</h5>
              <div className="d-flex flex-row justify-content-between align-items-center mt-3">
                {product.image && (
                  <div>
                    <img src={makeImageUrl(product.image)} alt="" width={200} />
                  </div>
                )}

                <div className="d-flex flex-row justify-content-between">
                  <div className="mt-1">
                    <DropZone selectedImages={(e) => selectedImages(e)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" ">
          <button type="submit" className="site-button" onClick={handleSave}>
            Kaydet
          </button>
        </div>
      </div>
    </AdminLayout>
  );
}
export default EditProduct;
