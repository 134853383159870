import CheckoutPage from '../CheckoutPage';
import AuthRoles from '@core/auth/AuthRoles';

const CheckoutPublicRoute = {
  /*
  auth: AuthRoles.onlyGuest,
*/
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: '/checkout',
      component: CheckoutPage,
    },
  ],
};

export default CheckoutPublicRoute;
