import axios from 'axios';

export const LOGIN_URL = 'v1/auth/login';
export const REGISTER_URL = 'v1/auth/register';
export const LOGOUT_URL = 'v1/auth/logout';
export const REQUEST_PASSWORD_URL = 'v1/auth/forgot-password';
export const CHANGE_PASSWORD = 'v1/auth/change-password';

// Profile
export const MY_CREDITS = 'v1/auth/my-credits';
export const MY_ADVERTS = 'v1/auth/my-adverts';

export const ME_URL = 'v1/auth/info';

// Campaign
export const CAMPAIGNS = 'v1/campaigns';

export function login({ email, password }) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register({ email, name, password }) {
  return axios.post(REGISTER_URL, { email, name, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function changePassword(data) {
  return axios.post(CHANGE_PASSWORD, data);
}

export function info() {
  return axios.post(ME_URL);
}

export function myCredits(pageNumber, pageSize, order) {
  return axios.get(`${MY_CREDITS}?page=${pageNumber + 1}&limit=${pageSize}&sortBy=createdAt:${order}`);
}

export function myAdverts(pageNumber, pageSize, order) {
  return axios.get(`${MY_ADVERTS}?page=${pageNumber + 1}&limit=${pageSize}&sortBy=createdAt:${order}&relation=advert`);
}

export function applyCampaign(code) {
  return axios.post(CAMPAIGNS, { code });
}
