import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const actionTypes = {
  SetNewOrder: '[Order] Set New Order',
  GetAllOrders: '[Order] Get All Orders',
};

const barcodeInitialState = {
  orders: [],
};

export const reducer = persistReducer(
  {
    storage,
    key: 'v713-codenra-order',
    whitelist: ['orders'],
  },
  (state = barcodeInitialState, action) => {
    switch (action.type) {
      case actionTypes.SetNewOrder: {
        const { order } = action.payload;
        let orders1 = state.orders.concat(order);
        return { ...state, orders: orders1 };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  SetNewOrder: (order) => ({
    type: actionTypes.SetNewOrder,
    payload: { order },
  }),
};
