import StaticPage from '../StaticPage';
import ChangeLog from '../ChangeLog';

const PagePublicRoute = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/page/log',
      component: ChangeLog,
    },
    {
      path: '/sayfa/:pageSlug',
      component: StaticPage,
    },
  ],
};

export default PagePublicRoute;
