import AuthRoles from '../../../@core/auth/AuthRoles';
import MyProfile from '../views/MyProfile';
import MyCredits from '../views/MyCredits';
import MyPassword from '../views/MyPassword';
import MyCampaign from '../views/MyCampaign';

const AuthRoute = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: AuthRoles.user,

  routes: [
    {
      path: '/my-profile',
      component: MyProfile,
    },
    {
      path: '/my-credits',
      component: MyCredits,
    },
    {
      path: '/my-campaign',
      component: MyCampaign,
    },
    {
      path: '/my-password',
      component: MyPassword,
    },
  ],
};

export default AuthRoute;
