import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Profilesidebar from 'markup/Element/Profilesidebar';
import { connect } from 'react-redux';
import * as auth from 'modules/Auth/_redux/authRedux';
import * as authCrud from 'modules/Auth/_redux/authCrud';
import UserProfileLayout from 'markup/Layout/UserProfileLayout';

function MyProfile(props) {
  const [profile, setProfile] = useState({
    name: '',
    surname: '',
    phone: '',
    phone2: '',
    email: '',
  });

  useEffect(() => {
    setProfile({ ...props.auth.user });
    /*authCrud
      .info()
      .then((res) => res.data)
      .then((result) => setProfile(result.user));*/
  }, []);

  function handleChange(e) {
    setProfile((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  return (
    <UserProfileLayout {...props}>
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                <Profilesidebar {...props} />
                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx job-profile">
                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">Üyelik Bilgileriniz</h5>
                      <Link to={'./'} className="site-button right-arrow button-sm float-right">
                        Anasayfa
                      </Link>
                    </div>
                    <div>
                      <div className="row m-b30">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Ad:</label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Ad"
                              onChange={(e) => handleChange}
                              value={profile.name}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Soyad:</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Soyad"
                              name={'surname'}
                              onChange={(e) => handleChange}
                              value={profile.surname}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>GSM:</label>
                            <input
                              type="text"
                              className="form-control"
                              name="phone"
                              onChange={(e) => handleChange}
                              value={profile.phone}
                              placeholder="905512345678"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Mail:</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="E-Posta"
                              name="email"
                              onChange={(e) => handleChange}
                              value={profile.email}
                            />
                          </div>
                        </div>
                      </div>

                      <button className="site-button m-b30">Kaydet</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserProfileLayout>
  );
}

export default connect(auth.reducer, auth.actions)(MyProfile);
