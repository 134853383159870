import React, { Component } from 'react';
import LayoutV1 from 'markup/Layout/LayoutV1';
import PaymentCreditCardModal from './PaymentCreditCardModal';
import Pusher from 'pusher-js';
import { connect } from 'react-redux';
// import * as initialEndpoint from 'modules/Initial/_redux/initialEndpoint';
import * as cashierEndpoint from 'modules/Cashier/_redux/cashierEndpoint';
import * as authRedux from 'modules/Auth/_redux/authRedux';
import * as orderRedux from 'modules/Order/_redux/orderRedux';
import * as basketRedux from 'modules/Basket/_redux/basketRedux';
import { toast } from 'react-toastify';
import { Backdrop } from '@material-ui/core';
import LayoutMargin from '../../markup/Layout/LayoutMargin';
import { Link } from 'react-router-dom';

const cashIcon = require('images/checkout/cash.png');
const creditCard = require('images/checkout/card.png');
const paymentSvg = require('images/checkout/payment.svg');

const pusher = new Pusher('27ef2bf6e16681849c0f', {
  cluster: 'eu',
  encrypted: true,
});

// let bnr3 = require('./../../images/lines.png');

class CheckoutPage extends Component {
  state = {
    show: false,
    items: [],
    packages: [],
    loading: false,
  };

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };

  cashOrder = () => {
    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    cashierEndpoint
      .createOrder(this.props.cartItems, this.props.deskSessions, this.props.lastReadedBarcodeOfCompany)
      .then((res) => res.data)
      .then((result) => {
        toast.success('Sipariş Tamamlandı.');
        this.setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
        this.props.SetNewOrder(result.order);
        this.props.Clean();
        this.props.history.push({
          pathname: '/dashboard',
        });
      });
  };

  componentDidMount() {
    // const channel = pusher.subscribe('this.props.user.id');
    // channel.bind('orderCompleted', this.redirectPage);
    /*initialEndpoint
      .getProducts()
      .then((res) => res.data.products)
      .then((result) => {
        this.setState((prevState) => ({
          ...prevState,
          packages: result,
        }));
      });*/
  }

  componentWillUnmount() {
    // pusher.unsubscribe(this.props.user.email);
  }

  redirectPage = (remoteData) => {
    this.props.setBalance(remoteData.balance);
    // toast(`Bakiyenize ${remoteData.balance} Alcoin olarak güncellendi.  `);

    this.props.history.push({
      pathname: '/',
      state: {
        remoteData,
      },
    });
  };

  selectProduct = (product) => {
    this.setState({
      show: true,
      items: [product],
    });
  };

  render() {
    if (this.state.loading) {
      return (
        <LayoutV1>
          <Backdrop open={this.state.loading}>Yükleniyor...</Backdrop>
        </LayoutV1>
      );
    }
    return (
      <LayoutMargin>
        <Backdrop open={this.state.loading} />
        <div className="page-content bg-white">
          <div className="container">
            <div className="d-flex justify-content-center flex-column py-3 ">
              <img src={paymentSvg} style={{ opacity: 0.2, height: 200 }} alt="" />
              <br />

              <div className=" d-flex flex-column flex-grow-1">
                {this.props.lastReadedSettings.paymentType.includes('cash') && (
                  <div className="d-flex flex-row align-items-center border" onClick={() => this.cashOrder()}>
                    <div className="p-2">
                      <img src={cashIcon} alt="Cash order" width={40} />
                    </div>
                    <div>
                      <h6>Ödeme</h6>
                      <p style={{ fontSize: 12 }}>İşletmeden çıkarken ödeyebilirsiniz.</p>
                    </div>
                  </div>
                )}
                {this.props.lastReadedSettings.paymentType.includes('credit') && (
                  <div
                    className="d-flex flex-row align-items-center border mt-2"
                    onClick={() => this.setState({ show: true })}
                  >
                    <div className="p-2">
                      <img src={creditCard} alt="Cash order" width={40} />
                    </div>
                    <div>
                      <h6>Kredi kartı</h6>
                      <p style={{ fontSize: 12, lineHeight: 1.3 }}>
                        Kredi kartını kullanabilirsiniz. <br /> Endişelenmeyin, aynı fiyat.
                      </p>
                    </div>
                  </div>
                )}
              </div>

              <PaymentCreditCardModal
                show={this.state.show}
                items={this.state.items}
                handleClose={() => this.handleClose()}
              />
            </div>
          </div>
        </div>
      </LayoutMargin>
    );
  }
}

//export default CheckoutPage;

const mapStateToProps = (state) => ({
  //items  : state.cartList.cart,
  user: state.auth.user,
  products: state.initial.products,
  cartItems: state.basket.cart,
  deskSessions: state.initial.deskSessions,
  lastReadedBarcodeOfCompany: state.initial.lastReadedBarcodeOfCompany,
  lastReadedSettings: state.initial.lastReadedSettings,
});

export default connect(mapStateToProps, {
  ...authRedux.actions,
  ...orderRedux.actions,
  ...basketRedux.actions,
})(CheckoutPage);
