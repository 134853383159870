import React from 'react';
import { connect } from 'react-redux';
import * as authRedux from 'modules/Auth/_redux/authRedux';
import * as initialRedux from 'modules/Initial/_redux/initialRedux';
import AdminLayout from 'markup/Layout/AdminLayout';
import MaterialTable from 'material-table';
import * as adminEdnpoint from 'modules/Admin/_redux/adminEndpoint';
import { toast } from 'react-toastify';

function AdminProducts(props) {
  return (
    <AdminLayout>
      <div className="page-content">
        <MaterialTable
          title="Ürünler"
          options={{
            search: false,
            pageSize: 10,
          }}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} / {count}',
            },
            header: {
              actions: 'İşlemler',
            },
            body: {
              emptyDataSourceMessage: '',
              filterRow: {
                filterTooltip: 'Filter',
              },
            },
          }}
          actions={[
            {
              icon: 'storefront',
              tooltip: 'Detay',
              onClick: (event, rowData) => {
                props.history.push(`/admin/products/${rowData.id}`);
              },
            },
            {
              icon: 'add',
              tooltip: 'Yeni',
              isFreeAction: true,
              onClick: (event, rowData) => {
                props.history.push(`/admin/products/add`);
              },
            },
          ]}
          columns={[
            { title: 'id', field: 'ID', hidden: true },
            { title: 'İsim', field: 'name' },
            { title: 'Fiyat', field: 'price' },
            { title: 'Kategori', field: 'category' },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              adminEdnpoint
                .getProducts(query.page, query.pageSize, 'desc')
                .then((res) => res.data)
                .then((result) => {
                  resolve({
                    data: result.results,
                    page: result.page - 1,
                    totalCount: result.totalResults,
                  });
                });
            })
          }
          editable={{
            isEditable: (rowData) => rowData.id,
            isDeletable: (rowData) => rowData.id,
            onRowDelete: (rowData) =>
              new Promise((resolve, reject) => {
                adminEdnpoint.deleteProduct(rowData.id).then((res) => {
                  toast.success('Ürün silindi.');
                  resolve();
                });
              }),
          }}
        />
      </div>
    </AdminLayout>
  );
}

export default connect(null, {
  ...authRedux.actions,
  ...initialRedux.actions,
})(AdminProducts);
