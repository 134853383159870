import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import produce from 'immer';

export const actionTypes = {
  Loading: '[Category] Loading',
  GetCategories: '[Category] Get Categories',
  SetCategories: '[Category] Set Categories',
  SetCategory: '[Category] Set Category',
  ResetCategory: '[Category] Reset Category',
  AddFilter: '[Category - Filter] Add Filter',
  AddSingleFilter: '[Category - Filter] Add Single Filter',
  AddFilterBulk: '[Category - Filter] Add Filter Bulk',
  RemoveFilter: '[Category - Filter] Remove Filter',
  ResetFilter: '[Category - Filter] Reset Filter',
  SetCategoryProducts: '[Category - Products] Set category products',
};

const initialAdvertState = {
  loading: false,
  categories: [],
  categoryDetail: {
    name: '',
    body: '',
  },
  categoryFilter: [],
  categoryProducts: [],
  selectedFilter: {},
};

export const reducer = persistReducer(
  {
    storage,
    key: 'v713-codenra-category',
    whitelist: ['categories', 'categoryDetail', 'categoryFilter'],
  },
  (state = initialAdvertState, action) => {
    switch (action.type) {
      case actionTypes.Loading: {
        return { ...state, loading: action.cond };
      }

      case actionTypes.SetCategories: {
        const { categories } = action.payload;
        return { ...state, categories };
      }
      case actionTypes.SetCategoryProducts: {
        const { products } = action.payload;
        return { ...state, categoryProducts: products };
      }

      case actionTypes.SetCategory: {
        const { category } = action.payload;
        return { ...state, categoryDetail: category };
      }

      case actionTypes.ResetCategory: {
        return { ...state, categoryDetail: initialAdvertState.categoryDetail };
      }

      case actionTypes.AddFilter: {
        const { parent, value } = action.payload.filter;

        return {
          ...state,
          selectedFilter: {
            ...state.selectedFilter,
            [parent]:
              // If initial start check, it was edit become object
              typeof state.selectedFilter[parent] === 'object'
                ? // If is a object then Explode values.
                  [...state.selectedFilter[parent], value]
                : // Create new array for parent.
                  [value],
          },
        };
      }

      case actionTypes.AddSingleFilter: {
        const { parent, value } = action.payload.filter;

        return {
          ...state,
          selectedFilter: {
            ...state.selectedFilter,
            [parent]: [value],
          },
        };
      }

      case actionTypes.AddFilterBulk: {
        const { parent, value } = action.payload.filter;

        if (value.length <= 0) {
          return {
            ...state,
            selectedFilter: produce(state.selectedFilter, (draft) => {
              delete draft[parent];
            }),
          };
        }

        return {
          ...state,
          selectedFilter: {
            ...state.selectedFilter,
            [parent]: value,
          },
        };
      }

      case actionTypes.ResetFilter: {
        return { ...state, selectedFilter: {} };
      }

      case actionTypes.RemoveFilter: {
        const { parent, value } = action.payload.filter;

        if (!value) {
          return {
            ...state,
            selectedFilter: produce(state.selectedFilter, (draft) => {
              delete draft[parent];
            }),
          };
        }

        return {
          ...state,
          selectedFilter: {
            ...state.selectedFilter,
            [parent]: produce(state.selectedFilter[parent], (draft) => {
              const index = draft.findIndex((todo) => todo === value);
              if (index !== -1) draft.splice(index, 1);
            }),
          },
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setLoading: (cond) => ({
    type: actionTypes.Loading,
    payload: cond,
  }),
  setCategories: (categories) => ({
    type: actionTypes.SetCategories,
    payload: { categories },
  }),
  setCategory: (category) => ({
    type: actionTypes.SetCategory,
    payload: { category },
  }),
  setCategoryProducts: (products) => ({
    type: actionTypes.SetCategoryProducts,
    payload: { products },
  }),
  // If will unmount at category detail then reset to category detail.
  resetCategory: () => ({
    type: actionTypes.ResetCategory,
  }),
  addFilter: (filter) => ({
    type: actionTypes.AddFilter,
    payload: { filter },
  }),
  addSingleFilter: (filter) => ({
    type: actionTypes.AddSingleFilter,
    payload: { filter },
  }),
  addFilterBulk: (filter) => ({
    type: actionTypes.AddFilterBulk,
    payload: { filter },
  }),
  removeFilter: (filter) => ({
    type: actionTypes.RemoveFilter,
    payload: { filter },
  }),
  resetFilter: () => ({
    type: actionTypes.ResetFilter,
  }),
};
