import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import * as basketRedux from 'modules/Basket/_redux/basketRedux';
import ProductItem from './ProductItem';
import { router_category } from '../helpers';

const CategoryLimitedView = (props) => {
  return (
    <div className="featured-product-area space-mb--25" key={props.key}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* section title */}
            <h2
              className="section-title space-mb--20  font-bold radius-md p-2"
              onClick={() => props.history.push(router_category(props.match.params.deskCode, props.category._id))}
            >
              {props.category.name}
              <a
                className="pr-2"
                onClick={() => props.history.push(router_category(props.match.params.deskCode, props.category._id))}
              >
                TÜMÜNÜ GÖSTER
                <span>
                  <ReactSVG src={process.env.PUBLIC_URL + '/assets/img/icons/arrow-right.svg'} />
                </span>
              </a>
            </h2>
            {/* featured products */}
            <div className="featured-product-wrapper space-mb-m--15">
              <div className="row row-5">
                {props.category.products &&
                  props.category.products.map((product) => {
                    // const wishlistItem = wishlistItems.filter((wishlistItem) => wishlistItem.id === product.id)[0];
                    return <ProductItem {...props} product={product} key={product._id} />;
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CategoryLimitedView.propTypes = {
  // addToWishlist: PropTypes.func,
  // products: PropTypes.array,
  // wishlistItems: PropTypes.array,
};
const mapStateToProps = (state, ownProps) => {
  return {
    // products: getProducts(state.productData.products, ownProps.limit, ownProps.type),
    // wishlistItems: state.wishlistData,
  };
};

export default connect(mapStateToProps, { ...basketRedux.actions })(withRouter(CategoryLimitedView));
