import React, { useEffect } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import * as categoryRedux from 'modules/Category/_redux/categoryRedux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const MultiSelectSection = (props) => {
  const { selectedFilter } = useSelector(
    (state) => ({
      selectedFilter: state.category.selectedFilter,
    }),
    shallowEqual
  );

  const handleFilterChange = (selecteds, parent) => {
    props.addFilterBulk({ parent, value: selecteds });
  };

  return (
    <aside id={props.filter.id} key={Math.random()} className=" sidebar-filter mt-3 mr-1">
      <div id="companies" className="acod-body collapse show">
        <div className="acod-content m-0">
          <Autocomplete
            onChange={(e, selecteds) => handleFilterChange(selecteds, props.filter.slug)}
            multiple
            limitTags={1}
            id="multiple-limit-tags"
            options={props.filter.values}
            getOptionLabel={(option) => option}
            defaultValue={selectedFilter[props.filter.slug]}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={props.filter.title}
                size="small"
                //placeholder={props.filter.title}
              />
            )}
            style={{ minWidth: '130px' }}
          />
        </div>
      </div>
    </aside>
  );
};

export default connect(null, {
  ...categoryRedux.actions,
})(MultiSelectSection);
