import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import LayoutV1 from 'markup/Layout/LayoutV1';
import * as categoryEndpoint from 'modules/Category/_redux/categoryEndpoint';
import * as categoryRedux from 'modules/Category/_redux/categoryRedux';
import { connect, shallowEqual, useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import DrawerMenu from './parts/filters/mobileSections/DrawerMenu';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LayoutMargin from '../../markup/Layout/LayoutMargin';
import ProductItem from '../../components/ProductItem';

function CategoryViewV1(props) {
  const myRef = useRef(null);

  /*const { filters } = useSelector(
    (state) => ({
      // filters: state.category.categoryDetail.filters,
    }),
    shallowEqual
  );*/
  const classes = useStyles();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    let categoryId = props.match.params.categoryId;
    let deskId = props.match.params.deskId;

    //console.log({ selectedFilter: props.selectedFilter });
    categoryEndpoint
      .getCategoryProduct(deskId, categoryId)
      .then((res) => res.data)
      .then((result) => {
        console.log({ result });
        // props.setAdvertsOfCategory(result.adverts);
        props.setCategoryProducts(result.products);
        props.setCategory(result.category);
      });
  }, []);

  function nextQuery(data) {
    myRef.current.scrollIntoView();
  }

  function backQuery(data) {
    myRef.current.scrollIntoView();
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      props.resetFilter();
      props.resetCategory();
    };
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const { categoryDetail, products } = props;

  return (
    <LayoutMargin>
      <div className="page-content bg-white">
        <div
          className="dez-bnr-inr"
          style={{
            boxShadow: '0 0 10px 0 rgb(0 0 0 / 6%)',
          }}
        >
          <div className="container">
            <div
              className="dez-bnr-inr-entry d-flex flex-column justify-content-center"
              style={{ textAlign: 'left', height: '100%' }}
            >
              <div className="row">
                <div className="col-10">
                  <h1 className="text-black m-0 pt-1 pb-1" style={{ textAlign: 'left' }}>
                    {categoryDetail.name}
                  </h1>
                  <p className="text-black m-0 pt-1 pb-1" style={{ lineHeight: 1.5 }}>
                    {categoryDetail.body}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="section-full browse-job p-b50 ">
            <div className="container">
              <div className="row">
                {products.map((product) => {
                  return <ProductItem product={product} key={product._id} />;
                })}
                {/*  {adverts.length >= 0 && (
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="job-bx-title clearfix">
                      <div className="float-left">
                        {width > 576 && (
                          <div className="d-flex">
                            <div className="d-flex align-items-end">
                              <DrawerMenu filters={filters} width={width} />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="d-flex flex-row-reverse float-right">
                        <div className="table-cell float-right p-tb5 p-r10 align-items-center justify-content-center">
                          <div className="d-flex flex-column align-items-center">
                            <div>
                              <Link to={'#'} className="p-lr5">
                                <i className="fa fa-th-list" />
                              </Link>
                              <Link to={'#'} className="p-lr5">
                                <i className="fa fa-th" />
                              </Link>
                            </div>
                            <p className={classes.contentStyle}>Görüntüle</p>
                          </div>
                        </div>
                        {width <= 576 && (
                          <div className="table-cell float-right p-1 d-flex flex-column align-items-center">
                            <DrawerMenu filters={filters} width={width} />
                          </div>
                        )}
                      </div>
                    </div>
                    <ul className="post-job-bx browse-job-grid row" style={{ padding: '0' }}>
                      {adverts.map((item, index) => (
                        <AdvertCard advert={item} key={index} />
                      ))}
                    </ul>
                    {adverts.length > 0 && (
                      <div className="pagination-bx float-right m-t30">
                        <ul className="pagination">
                          <li className="previous">
                            <Link to={''}>
                              <i className="ti-arrow-left"></i> Önceki
                            </Link>
                          </li>
                          <li className="active">
                            <Link to={''}>1</Link>
                          </li>
                          <li>
                            <Link to={''}>2</Link>
                          </li>
                          <li>
                            <Link to={''}>3</Link>
                          </li>
                          <li className="next">
                            <Link to={''}>
                              Sonraki <i className="ti-arrow-right"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                )}
                {adverts.length === 0 && (
                  <div className="col-12 content-inner-2">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="p-3">
                        <h3 className="m-b5 pl-3 text-center">Aramanıza uygun ilan bulunamadı.</h3>
                      </div>
                      <div>
                        <Button variant="contained" color="primary" onClick={() => props.history.push('/')}>
                          Home
                        </Button>
                      </div>
                    </div>
                  </div>
                )}*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutMargin>
  );
}

const useStyles = makeStyles((theme) => ({
  contentStyle: {
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '6px 16px',
    minWidth: '64px',
    boxSizing: 'border-box',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: '4px',
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    fontSize: '10px',
    margin: 0,
  },
}));

const mapStateToProps = (state) => ({
  //items  : state.cartList.cart,
  categoryDetail: state.category.categoryDetail,
  products: state.category.categoryProducts,
  // selectedFilter: state.category.selectedFilter,
});

export default connect(mapStateToProps, { ...categoryRedux.actions })(CategoryViewV1);
