import AuthRoles from '@core/auth/AuthRoles';
import AdminDashboard from 'modules/Admin/views/AdminDashboard';
import AdminUsers from 'modules/Admin/views/AdminUsers';
import AdminProducts from 'modules/Admin/views/AdminProducts';
import AdminOrders from 'modules/Admin/views/AdminOrders';
import AdminCategories from 'modules/Admin/views/AdminCategory/AdminCategories';
import AdminSettings from 'modules/Admin/views/AdminSettings/AdminSettings';
import AdminSearch from 'modules/Admin/views/AdminSearch';
import AdminPages from 'modules/Admin/views/AdminPages';
import AdminCategoryDetail from 'modules/Admin/views/AdminCategory/AdminCategoryDetail';
import AdminCreateConstantPage from '../views/AdminSettings/Constant Page/AdminCreateConstantPage';
import AdminUsersDetail from '../views/AdminUsersDetail';
import AdminEmployees from '../views/AdminEmployees';
import AdminBarcodes from '../views/AdminBarcodes';
import AdminBarcodeDetail from '../views/AdminBarcodeDetail';
import EditProduct from '../views/AdminProduct/EditProduct';
import AdminBarcodeCategoryCrud from '../views/AdminBarcodeCategoryCrud';
import AddProduct from '../views/AdminProduct/AddProduct';

const AdminPrivateRoute = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: '/admin/dashboard',
      component: AdminDashboard,
    },
    {
      path: '/admin/users/:userId',
      component: AdminUsersDetail,
    },
    {
      path: '/admin/users',
      component: AdminUsers,
    },
    {
      path: '/admin/categories/:categoryId',
      component: AdminCategoryDetail,
    },
    {
      path: '/admin/categories',
      component: AdminCategories,
    },
    {
      path: '/admin/products/add',
      component: AddProduct,
    },
    {
      path: '/admin/products/:productId',
      component: EditProduct,
    },
    {
      path: '/admin/products',
      component: AdminProducts,
    },
    {
      path: '/admin/employees',
      component: AdminEmployees,
    },
    {
      path: '/admin/barcodes/category',
      component: AdminBarcodeCategoryCrud,
    },
    {
      path: '/admin/barcodes/:barcodeId',
      component: AdminBarcodeDetail,
    },
    {
      path: '/admin/barcodes',
      component: AdminBarcodes,
    },
    {
      path: '/admin/orders',
      component: AdminOrders,
    },
    {
      path: '/admin/settings',
      component: AdminSettings,
    },
    {
      path: '/admin/search',
      component: AdminSearch,
    },
    {
      path: '/admin/pages/create',
      component: AdminCreateConstantPage,
    },
    {
      path: '/admin/pages',
      component: AdminPages,
    },
  ],
};

export default AdminPrivateRoute;
