import React, { useState } from 'react';
import LayoutV1 from '../../../markup/Layout/LayoutV1';
import '../views/contact.css';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Button from '@material-ui/core/Button';
import ContactForm from './ContactForm';

function Contact(props) {
  const [open, setOpen] = useState(false);
  return (
    <LayoutV1>
      <div className="page-content bg-white" style={{ paddingTop: '50px' }}>
        <div className="content-block">
          <div className="container">
            <div className="row d-flex align-items-stretch justify-content-center">
              <div className="col-12 col-sm-5 contact-card">
                <a
                  className="contact-card-container  d-flex flex-column justify-content-center align-items-center"
                  href="tel:08502555777"
                >
                  <div className="contact-header pt-4 ">
                    <div className="contact-icon-container">
                      <PhoneIcon className="contact-card-icons" />
                    </div>
                    <div className="pt-2 pb-2 contact-icon-header">telefon</div>
                  </div>
                  <div className="pt-2 pb-4">
                    <div className="contact-body">Müşteri Hizmetleri</div>
                    <div className="contact-footer">0 850 2 555 777</div>
                  </div>
                </a>
                {open && props.id === '2' && <ContactForm open={open} setOpen={setOpen} />}
              </div>
              <div className="col-12 col-sm-5 contact-card">
                <div className="contact-card-container  d-flex flex-column justify-content-center align-items-center">
                  <div className="contact-header pt-4">
                    <div className="contact-icon-container">
                      <MailOutlineIcon className="contact-card-icons" />
                    </div>
                    <div className="pt-2 pb-2 contact-icon-header">Bize Ulaşın</div>
                  </div>
                  <div className="pt-2 pb-4">
                    <div className="d-flex justify-content-center p-3">
                      {' '}
                      <Button
                        variant="contained"
                        style={{ background: '#11986E', color: '#fff' }}
                        onClick={() => setOpen(true)}
                      >
                        Destek
                      </Button>
                    </div>
                  </div>
                </div>
                {open && <ContactForm open={open} setOpen={setOpen} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutV1>
  );
}

export default Contact;
