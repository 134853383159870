import React, { useEffect, useState } from 'react';
import LayoutV1 from 'markup/Layout/LayoutV1';
import { getOrders } from '../_redux/orderEndpoint';
import { connect } from 'react-redux';
import * as initialRedux from '../../Initial/_redux/initialRedux';
import { Backdrop, Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import LayoutMargin from '../../../markup/Layout/LayoutMargin';
import BreadcrumbsCustom from '../../../components/BreadcrumbsCustom';
import { getGrandtotal } from 'helpers/PriceHelper';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { router_order_detail } from 'helpers';

function Orders(props) {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  /*const [session, setSession] = useState({
    session: '',
    company: {
      name: '',
    },
  });*/
  useEffect(() => {
    let lastOrders = _.take(_.orderBy(props.orders, ['createdAt'], ['desc']), 50);
    setOrders(lastOrders);
  }, []);
  useEffect(() => {
    let deskCode = props.match.params.deskCode;
    window.scrollTo(0, 0);

    // props.createSession()
    getOrders(deskCode).then((res) => {
      setLoading(false);
      // setSession(res.data);
      // props.createSessionFromDesk(res.data);
    });
  }, []);

  if (loading) {
    return (
      <LayoutV1>
        <Backdrop open={true}>Yükleniyor...</Backdrop>
      </LayoutV1>
    );
  }
  return (
    <LayoutMargin>
 <BreadcrumbsCustom pageTitle="Siparişler" prevUrl={'/home'} />
      <div className="container">
        {orders.map((order, index) => (
          <div className="border shadow my-3 p-2 d-flex flex-column" key={index}>
            <div style={{ fontWeight: 'bold' }} className="d-flex justify-content-between">
              <span>{order.company.name}</span>
              <span>{moment(order.createdAt).fromNow()}</span>
            </div>

            <div className="d-flex justify-content-end">
              <Link to={`/orders/${order.id}`} className="site-button">
                Detay
              </Link>
            </div>
          </div>
        ))}
      </div>
    </LayoutMargin>
  );
}

// export default BarcodeDetail;
const mapStateToProps = (state) => ({
  initialRedux: state.initial.initialRedux,
  orders: state.orders.orders,
});

export default connect(mapStateToProps, { ...initialRedux.actions })(Orders);
