import AuthRoles from '@core/auth/AuthRoles';
import StaffDashboard from '../views/StaffDashboard';

const StaffPrivateRoute = {
  auth: AuthRoles.staff,
  routes: [
    {
      path: '/staff/dashboard',
      component: StaffDashboard,
    } /*,
    {
      path: '/admin/users/:userId',
      component: AdminUsersDetail,
    },
    {
      path: '/admin/users',
      component: AdminUsers,
    },
    {
      path: '/admin/categories/:categoryId',
      component: AdminCategoryDetail,
    },
    {
      path: '/admin/categories',
      component: AdminCategories,
    },
    {
      path: '/admin/products/add',
      component: AddProduct,
    },
    {
      path: '/admin/products/:productId',
      component: EditProduct,
    },
    {
      path: '/admin/products',
      component: AdminProducts,
    },
    {
      path: '/admin/employees',
      component: AdminEmployees,
    },
    {
      path: '/admin/barcodes/category',
      component: AdminBarcodeCategoryCrud,
    },
    {
      path: '/admin/barcodes/:barcodeId',
      component: AdminBarcodeDetail,
    },
    {
      path: '/admin/barcodes',
      component: AdminBarcodes,
    },
    {
      path: '/admin/orders',
      component: AdminOrders,
    },
    {
      path: '/admin/settings',
      component: AdminSettings,
    },
    {
      path: '/admin/search',
      component: AdminSearch,
    },
    {
      path: '/admin/pages/create',
      component: AdminCreateConstantPage,
    },
    {
      path: '/admin/pages',
      component: AdminPages,
    },*/,
  ],
};

export default StaffPrivateRoute;
