import React, { useEffect, useState } from 'react';
import LayoutV1 from 'markup/Layout/LayoutV1';
import { connect, shallowEqual, useSelector } from 'react-redux';
import * as basketRedux from '../../Basket/_redux/basketRedux';
import { Link } from 'react-router-dom';
import { getCustomerPrice, getGrandtotal } from 'helpers/PriceHelper';
import { makeImageUrl } from '../../../helpers/PathHelper';
// import CategorySlider from 'components/CategorySlider';
// import BestSellerProduct from '../../../components/BestSellerProduct';

const data = {
  companyName: 'Bahane Kültür',
  zoneName: 'A15',
  categories: [
    { _id: '1', name: 'Kate 1', image: 'den1.jpg' },
    { _id: '2', name: 'Kate 2', image: 'den2.jpg' },
    { _id: '3', name: 'Kate 3', image: 'den3.jpg' },
    { _id: '4', name: 'Kate 4', image: 'den4.jpg' },
  ],
};

function BasketIndex(props) {
  const [cartTotalPrice] = useState('11');
  useEffect(() => {
    console.log({ props });
    window.scrollTo(0, 0);
  }, []);

  function incrementQtyButton(product) {
    props.AddItemToBasket(product);
  }
  function removeItemFromBasket(index) {
    props.RemoveItemAction(index);
  }

  function decrementQtyButton(index) {
    props.DecrementQtyButton(index);
  }

  const { cartItems } = props;

  return (
    <LayoutV1>
      <div className="page-content">
        <br />
        <br />
        <br />
        <div className="container">
          <div className="cart-product-area">
            {/*{cartItems.length}*/}
            {cartItems.map((cartItem, key) => {
              const discountedPrice = getCustomerPrice(cartItem.price, cartItem.discount);

              return (
                <div className="cart-product border-bottom--medium" key={key}>
                  <div className="cart-product__image">
                    <Link to={process.env.PUBLIC_URL + `/product/${cartItem.id}`}>
                      <img src={makeImageUrl(cartItem.image)} className="img-fluid" alt="" />
                    </Link>
                  </div>
                  <div className="cart-product__content">
                    <h3 className="title">
                      <Link to={process.env.PUBLIC_URL + `/product/${cartItem.id}`}>{cartItem.name}</Link>
                    </h3>
                    <span className="category">{cartItem.category.name}</span>
                    <div className="price">
                      {cartItem.discount && cartItem.discount > 0 ? (
                        <div>
                          <span className="main-price mr-1 font-15">{`£${cartItem.price}`}</span>
                          <span className="discounted-price">{`£${getCustomerPrice(
                            cartItem.price,
                            cartItem.discount
                          )}`}</span>
                        </div>
                      ) : (
                        <span className="discounted-price">{`£${cartItem.price}`}</span>
                      )}
                    </div>
                    {cartItem.selectedProductColor ? (
                      <div className="cart-item-variation">
                        <span>Renk: {cartItem.selectedProductColor}</span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="cart-product__counter">
                    <div className="cart-plus-minus">
                      {cartItem.qty === 1 ? (
                        <button className="dec qtybutton" onClick={() => removeItemFromBasket(key)}>
                          <i className="fa fa-trash" style={{ fontSize: 14 }}></i>
                        </button>
                      ) : (
                        <button className="dec qtybutton" onClick={() => decrementQtyButton(key)}>
                          <i className="fa fa-minus" style={{ fontSize: 12 }}></i>
                        </button>
                      )}

                      <input className="cart-plus-minus-box" type="text" value={cartItem.qty} readOnly />
                      <button
                        className="inc qtybutton"
                        onClick={() => incrementQtyButton(cartItem)}
                        /*disabled={cartItem !== undefined}*/
                      >
                        <i className="fa fa-plus" style={{ fontSize: 12 }}></i>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {cartItems.length < 1 ? (
            <div className="d-flex  flex-column align-items-center mt-5">
              <div className="font-weight-bold mt-1 font-22">Boş</div>
              <img src={require('images/cart.svg')} alt="" style={{ height: 75 }} />
            </div>
          ) : (
            <div className="d-flex justify-content-center mt-3">
              <button onClick={() => props.Clean()} className="button btn">
                Tüm Ürünleri Kaldır
              </button>
            </div>
          )}
        </div>
        <div>
          <div className="grand-total space-mt--20">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {cartItems.length > 0 && (
                    <div>
                      <div className="title-wrap">
                        <h4 className="cart-bottom-title">Sepet Toplamı</h4>
                      </div>
                      <h5>
                        Tahmini Teslimat Süresi <span>15 Dakika</span>
                      </h5>
                      <h4 className="grand-total-title">
                        Genel Toplam <span>£{props.grandTotal}</span>
                      </h4>
                      <Link to={process.env.PUBLIC_URL + '/checkout'}>Ödemeye İlerle</Link>
                      <br />
                    </div>
                  )}
                  <Link to={process.env.PUBLIC_URL + '/home'} style={{ backgroundColor: 'red' }}>
                    Menüye Geri Dön
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutV1>
  );
}

// export default BasketIndex;
const mapStateToProps = (state) => ({
  cartItems: state.basket.cart,
  grandTotal: getGrandtotal(state.basket.cart),
});
export default connect(mapStateToProps, { ...basketRedux.actions })(BasketIndex);
