import BasketIndex from '../views/BasketIndex';

const BasketPublicRoute = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/basket/index',
      component: BasketIndex,
    },
  ],
};

export default BasketPublicRoute;
