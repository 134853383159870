import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as auth from 'modules/Auth/_redux/authRedux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

function SocialBar(props) {
  return (
    <div className="dz-social clearfix">
      <ul className="dez-social-icon dez-border  dez-social-icon-lg text-white d-flex flex-row justify-content-center"></ul>
    </div>
  );
}

export default connect(null, auth.actions)(withRouter(SocialBar));
