import { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import AppContext from 'AppContext';

import * as initialEndpoint from 'modules/Initial/_redux/initialEndpoint';
import * as authRedux from 'modules/Auth/_redux/authRedux';
import * as initialRedux from 'modules/Initial/_redux/initialRedux';
import * as categoryRedux from 'modules/Category/_redux/categoryRedux';

function DynamicRoutes(props) {
  const appContext = useContext(AppContext);
  const { routes } = appContext;

  useEffect(() => {
    console.log('initial request here!!');
    /*initialEndpoint
      .getInitial()
      .then((res) => res.data)
      .then((result) => {
        props.setAdverts(result.adverts.results);
        props.setCategories(result.categories);
        props.setProducts(result.products);
        props.setPages(result.pages);
        props.setSelectedCities(result.cities);
        props.setUserCity(result.userCity);
      });*/
  }, []);

  return renderRoutes(routes);
}
export default connect(null, {
  ...authRedux.actions,
  ...initialRedux.actions,
  ...categoryRedux.actions,
})(withRouter(DynamicRoutes));
