import axios from 'axios';

export const GET_INITIAL = 'v1/initials?sortBy=createdAt:desc';
export const GET_PRODUCTS = 'v1/products';
export const GET_TURKEY_PROVINCES = 'v1/initials/turkeyProvinces';
export const GET_TURKEY_DISCTIRCT = 'v1/initials/getTurkeyDistrictByCode';

export function getInitial() {
  return axios.get(GET_INITIAL);
}

export function createOrder() {
  return axios.get(GET_INITIAL);
}

export function getProducts() {
  return axios.get(GET_PRODUCTS);
}
export function getProductDetail(productId) {
  return axios.get(`${GET_PRODUCTS}/${productId}`);
}

export function getTurkeyDistrictByCode(code) {
  return axios.get(`${GET_TURKEY_DISCTIRCT}/${code}`);
}
