import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as authRedux from 'modules/Auth/_redux/authRedux';
import * as initialRedux from 'modules/Initial/_redux/initialRedux';
import AdminLayout from 'markup/Layout/AdminLayout';
import MaterialTable from 'material-table';
import { getUTCDate } from 'helpers';
import * as adminEndpoint from 'modules/Admin/_redux/adminEndpoint';
import { useHistory } from 'react-router-dom';

function Dashboard(props) {
  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AdminLayout>
      <div className="page-content bg-white">
        <MaterialTable
          title="Kullanıcılar"
          options={{
            search: false,
            pageSize: 10,
            //toolbar: false,
          }}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} / {count}',
            },
            header: {
              actions: 'İşlem',
            },
            body: {
              emptyDataSourceMessage: 'Satın aldığınız ilan bulunmuyor.',
              filterRow: {
                filterTooltip: 'Filtre',
              },
            },
          }}
          actions={[
            {
              icon: 'help',
              tooltip: 'Detail',
              onClick: (event, rowData) => {
                window.open(`/admin/users/${rowData.id}`);
              },
            },
          ]}
          columns={[
            { title: 'id', field: 'ID', hidden: true },
            { title: 'Ad', field: 'name' },
            { title: 'Soyad', field: 'surname' },
            { title: 'E-Posta', field: 'email' },

            {
              title: 'Üyelik',
              field: 'createdAt',
              render: (rowData) => <span>{getUTCDate(rowData.createdAt)}</span>,
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              adminEndpoint
                .getUsers(query.page, query.pageSize, 'desc')
                .then((res) => res.data)
                .then((result) => {
                  resolve({
                    data: result.results,
                    page: result.page - 1,
                    totalCount: result.totalResults,
                  });
                });
            })
          }
          editable={{
            isEditable: (rowData) => rowData.id,
            isDeletable: (rowData) => rowData.id,
          }}
        />
      </div>
    </AdminLayout>
  );
}

export default connect(null, {
  ...authRedux.actions,
  ...initialRedux.actions,
})(Dashboard);
